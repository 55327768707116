/**
 * パフォーマンス改善対応
 * 「アイテム詳細画面」以外については、
 * 遅延読み込みを利用する
 */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ContentDetailPage from '@/views/page/ContentDetailPage.vue'

// tracking
import tracker from '@/helpers/tracking'
import makeUpdatedPidString from '@/domain/item/itemViewer/makeUpdatedPidString'
import { store } from '@/store'
import { createFromParameter, createToParameter, getCardId } from '@/store/modules/StatisticsLog'
import StringKeyObject from '@/data/@types/StringKeyObject'
import { sessionCheck } from '@/domain/session/sessionCheck'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main',
    component: () => import('@/views/page/Main.vue' /* webpackChunkName: "main-page" */),
    props: route => ({
      keyword: route.query.keyword,
      categoryCode: route.query.categoryCode,
      viewRestricted: route.query.viewRestricted,
      pageNo: route.query.pageNo,
    }),
    children: [
      {
        path: '/',
        name: 'TopPage',
        meta: {
          description: { en: 'The NDL Digital Collections enables a user to search and view a variety of resources, collected and stored by the National Diet Library of Japan.', ja: '国立国会図書館デジタルコレクションは、国立国会図書館で収集・保存しているデジタル資料を検索・閲覧できるサービスです。' },
          pageTitle: { en: 'NDL Digital Collections', ja: '国立国会図書館デジタルコレクション' },
          ogType: 'website',
        },
        component: () => import('@/views/page/TopPage.vue' /* webpackChunkName: "top-page" */),
      },
    ],
  },
  {
    path: '/mycollection',
    name: 'MyCollection',
    component: () => import('@/views/page/MyCollection.vue' /* webpackChunkName: "my-collection" */),
    meta: {
      description: { en: 'sample description', ja: 'サンプル 説明' },
      pageTitle: { en: 'My Collection', ja: 'マイコレクション' },
      ogType: 'article',
    },
    props: {
      component: 'TheMyCollectionMain',
    },
  },
  {
    path: '/search',
    name: 'DetailedSearch',
    component: () => import('@/views/page/DetailSearchFormPage.vue' /* webpackChunkName: "detail-search-form-page" */),
    meta: {
      pageTitle: { en: 'Advanced search - NDL Digital Collections', ja: '詳細検索 - 国立国会図書館デジタルコレクション' },
      ogType: 'article',
    },
    children: [
      {
        path: '/search/searchResult',
        name: 'DetailedSearchResult',
        component: () => import('@/views/page/SearchResultPage.vue' /* webpackChunkName: "search-result-page" */),
        props: {
          component: 'TheSearchResultMain',
        },
        meta: {
          pageTitle: { en: 'Search results - NDL Digital Collections', ja: '検索結果 - 国立国会図書館デジタルコレクション' },
          ogType: 'article',
        },
      },
    ],
  },
  {
    path: '/collections/:collectionId(.+)',
    name: 'CollectionDetailedSearch',
    component: () => import('@/views/page/CollectionDetailPage.vue' /* webpackChunkName: "collection-detail-page" */),
    meta: {
      pageTitle: { en: 'collection search - NDL Digital Collections', ja: 'コレクション検索 - 国立国会図書館デジタルコレクション' },
      description: { en: '', ja: '' },
      ogType: 'article',
    },
    children: [
      {
        path: '/collections/:collectionId(.+)',
        name: 'CollectionDetailedSearchResult',
        component: () => import('@/views/page/SearchResultPage.vue' /* webpackChunkName: "search-result-page" */),
        props: {
          component: 'TheSearchResultMain',
        },
        meta: {
          pageTitle: { en: 'collection search - NDL Digital Collections', ja: 'コレクション検索 - 国立国会図書館デジタルコレクション' },
          description: { en: '', ja: '' },
          ogType: 'article',
        },
      },
    ],
  },
  {
    path: '/search/image',
    name: 'SearchImage',
    props: route => ({
      keyword: route.query.keyword,
    }),
    component: () => import('@/views/page/SearchImage.vue' /* webpackChunkName: "search-image" */),
    meta: {
      pageTitle: { en: 'Image search - NDL Digital Collections', ja: '画像検索 - 国立国会図書館デジタルコレクション' },
      description: { en: '', ja: '' },
      ogType: 'article',
    },
    children: [
      {
        path: '/search/image/result',
        name: 'SearchImageResult',
        component: () => import('@/views/page/SearchResultPage.vue' /* webpackChunkName: "search-result-page" */),
        props: {
          component: 'TheSearchImageResultMain',
        },
        meta: {
          pageTitle: { en: 'Image search result - NDL Digital Collections', ja: '画像検索結果 - 国立国会図書館デジタルコレクション' },
          description: { en: '', ja: '' },
          ogType: 'article',
        },
      },
    ],
  },
  {
    path: '/pid/:pid(\\d+)/:bundleIndex(\\d+)?/:contentIndex(\\d+)?',
    name: 'ContentDetail',
    component: ContentDetailPage,
    meta: {
      pageTitle: { en: 'Item detail - NDL Digital Collections', ja: 'アイテム詳細画面 - 国立国会図書館デジタルコレクション' },
      description: { en: '', ja: '' },
      ogType: 'article',
    },
  },
  {
    path: '/iframepid/:pid(\\d+)/:bundleIndex(\\d+)?/:contentIndex(\\d+)?',
    // 初回のみこのページが呼ばれ、isIframeがtrueになる
    // その後の画面遷移ではContentDetailに遷移するため、isIframeはVuexで保存する
    name: 'ContentDetailWithIframe',
    component: ContentDetailPage,
    meta: {
      pageTitle: { en: 'Item detail - NDL Digital Collections', ja: 'アイテム詳細画面 - 国立国会図書館デジタルコレクション' },
      description: { en: '', ja: '' },
      ogType: 'article',
      isIframe: true,
    },
  },
  {
    path: '/statistics',
    component: () => import('@/views/page/StatisticsPage.vue'),
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import('../views/page/Static.vue' /* webpackChunkName: "static" */),
    meta: {
      pageTitle: { en: 'Help - NDL Digital Collections', ja: 'ヘルプ - 国立国会図書館デジタルコレクション' },
      description: { en: 'Help(description)', ja: 'ヘルプ(description)' },
      ogType: 'article',
    },
    props: {
      htmlName: 'help.html',
    },
  },
  {
    path: '/sitepolicy',
    name: 'Sitepolicy',
    component: () => import('../views/page/Static.vue' /* webpackChunkName: "static" */),
    meta: {
      pageTitle: { en: 'Terms of Use - NDL Digital Collections', ja: '利用上の注意 - 国立国会図書館デジタルコレクション' },
      description: { en: 'Terms of Use(description)', ja: '利用上の注意(description)' },
      ogType: 'article',
    },
    props: {
      htmlName: 'sitepolicy.html',
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/page/Static.vue' /* webpackChunkName: "static" */),
    meta: {
      pageTitle: { en: 'Contact - NDL Digital Collections', ja: 'コンタクト - 国立国会図書館デジタルコレクション' },
      description: { en: '(description)', ja: '(description)' },
      ogType: 'article',
    },
    props: {
      htmlName: 'contact.html',
    },
  },
  {
    path: '/privacypolicy',
    name: 'Privacypolicy',
    component: () => import('../views/page/Static.vue' /* webpackChunkName: "static" */),
    meta: {
      pageTitle: { en: 'Privacy policy - NDL Digital Collections', ja: 'プライバシーポリシー - 国立国会図書館デジタルコレクション' },
      description: { en: 'Privacy policy(description)', ja: 'プライバシーポリシー(description)' },
      ogType: 'article',
    },
    props: {
      htmlName: 'privacypolicy.html',
    },
  },
  {
    path: '/intro',
    name: 'Intro',
    component: () => import('../views/page/Static.vue' /* webpackChunkName: "static" */),
    meta: {
      pageTitle: { en: 'About - NDL Digital Collections', ja: 'このデータベースについて - 国立国会図書館デジタルコレクション' },
      description: { en: '(description)', ja: '(description)' },
      ogType: 'article',
    },
    props: {
      htmlName: 'intro.html',
    },
  },
  {
    path: '/information',
    name: 'Information',
    component: () => import('../views/page/Static.vue' /* webpackChunkName: "static" */),
    meta: {
      pageTitle: { en: 'Information - NDL Digital Collections', ja: 'インフォメーション - 国立国会図書館デジタルコレクション' },
      description: { en: '(description)', ja: '(description)' },
      ogType: 'article',
    },
    props: {
      htmlName: 'information.html',
    },
  },
  {
    path: '/collection',
    name: 'Collection',
    component: () => import('@/views/page/Collection.vue' /* webpackChunkName: "collection-page" */),
    meta: {
      pageTitle: { en: 'Collection - NDL Digital Collections', ja: 'コレクション - 国立国会図書館デジタルコレクション' },
      description: { en: '(description)', ja: '(description)' },
      ogType: 'article',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/page/BuiltinLogin.vue' /* webpackChunkName: "builtin-login" */),
    meta: {
      pageTitle: { en: 'Login', ja: 'ログイン' },
      description: { en: '', ja: '' },
      ogType: 'article',
    },
  },
  {
    path: '/tool/login',
    name: 'ToolLogin',
    component: () => import('../views/page/ToolLoginPage.vue' /* webpackChunkName: "builtin-login-tool" */),
    meta: {
      pageTitle: { en: 'Auto Login With Login Tool', ja: 'ログインツールでの自動ログイン' },
      description: { en: '', ja: '' },
      ogType: 'article',
    },
  },
  // 以下DMS画面
  {
    path: '/dms',
    name: 'DmsMain',
    component: () => import('@/views/dms/DmsMain.vue'),
    children: [
      {
        path: '/dms/online/login',
        name: 'OnlineLoginPage',
        component: () => import('@/views/dms/OnlineLoginPage.vue' /* webpackChunkName: "online-login-page" */),
        meta: {
          pageTitle: { en: 'OnlineLogin - NDL Digital Collections', ja: 'オンライン資料送信ログイン - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/doctor/login',
        name: 'DoctorLoginPage',
        component: () => import('@/views/dms/DoctorLoginPage.vue' /* webpackChunkName: "doctor-login-page" */),
        meta: {
          pageTitle: { en: 'DoctorLogin - NDL Digital Collections', ja: '博士論文送信ログイン - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/supportVisual/login',
        name: 'SupportVisualLoginPage',
        component: () => import('@/views/dms/SupportVisualLoginPage.vue' /* webpackChunkName: "support-visual-login-page" */),
        meta: {
          pageTitle: { en: 'SupportVisualLogin - NDL Digital Collections', ja: '視覚障害者等用データ送信ログイン - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/relogin',
        name: 'ReLoginPage',
        component: () => import('@/views/dms/ReLoginPage.vue' /* webpackChunkName: "re-login-page" */),
        meta: {
          pageTitle: { en: 'ReLogin - NDL Digital Collections', ja: '再ログイン - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/outer',
        name: 'OuterTopPage',
        component: () => import('@/views/dms/OuterTopPage.vue' /* webpackChunkName: "outer-top-page" */),
        meta: {
          pageTitle: { en: 'OuterTop - NDL Digital Collections', ja: '外部提供者用トップ - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/outer/passwordEdit',
        name: 'OuterPasswordEditPage',
        component: () => import('@/views/dms/OuterPasswordEditPage.vue' /* webpackChunkName: "outer-password-edit-page" */),
        meta: {
          pageTitle: { en: 'OuterPasswordEdit - NDL Digital Collections', ja: 'パスワード変更 - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/outer/passwordComplete',
        name: 'OuterPasswordCompletePage',
        component: () => import('@/views/dms/OuterPasswordCompletePage.vue' /* webpackChunkName: "outer-password-complete-page" */),
        meta: {
          pageTitle: { en: 'OuterPasswordComplete - NDL Digital Collections', ja: 'パスワード変更完了 - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/outer/outputSelect',
        name: 'OuterOutputSelectPage',
        component: () => import('@/views/dms/OuterOutputSelectPage.vue' /* webpackChunkName: "outer-output-select-page" */),
        meta: {
          pageTitle: { en: 'OuterOutputSelect - NDL Digital Collections', ja: '外部提供者用資料の送信方法選択 - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/outer/contentsInput',
        name: 'OuterContentsInputPage',
        component: () => import('@/views/dms/OuterContentsInputPage.vue' /* webpackChunkName: "outer-contents-input-page" */),
        meta: {
          pageTitle: { en: 'OuterContentsInput - NDL Digital Collections', ja: '資料送信 - ファイルアップロード - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/outer/metadataInput',
        name: 'OuterMetadataInputPage',
        component: () => import('@/views/dms/OuterMetadataInputPage.vue' /* webpackChunkName: "outer-metadata-input-page" */),
        meta: {
          pageTitle: { en: 'OuterMetadataInput - NDL Digital Collections', ja: '資料送信 - メタデータ入力 - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/outer/inputConfirm',
        name: 'OuterInputConfirmPage',
        component: () => import('@/views/dms/OuterInputConfirmPage.vue' /* webpackChunkName: "outer-input-confirm-page" */),
        meta: {
          pageTitle: { en: 'OuterInputConfirm - NDL Digital Collections', ja: '資料送信 - 内容確認 - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/outer/inputComplete',
        name: 'OuterInputCompletePage',
        component: () => import('@/views/dms/OuterInputCompletePage.vue' /* webpackChunkName: "outer-input-complete-page" */),
        meta: {
          pageTitle: { en: 'OuterInputComplete - NDL Digital Collections', ja: '資料送信 - 完了 - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/outer/fileInput',
        name: 'OuterFileInputPage',
        component: () => import('@/views/dms/OuterFileInputPage.vue' /* webpackChunkName: "outer-file-input-page" */),
        meta: {
          pageTitle: { en: 'OuterFileInput - NDL Digital Collections', ja: '資料一括送信 - ファイルアップロード - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/outer/fileComplete',
        name: 'OuterFileCompletePage',
        component: () => import('@/views/dms/OuterFileCompletePage.vue' /* webpackChunkName: "outer-file-complete-page" */),
        meta: {
          pageTitle: { en: 'OuterFileComplete - NDL Digital Collections', ja: '資料一括送信 - 完了 - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/outer/select',
        name: 'OuterSelectPage',
        component: () => import('@/views/dms/OuterSelectPage.vue' /* webpackChunkName: "outer-select-page" */),
        meta: {
          pageTitle: { en: 'OuterSelect - NDL Digital Collections', ja: '資料選択 - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/outer/list',
        name: 'OuterListPage',
        component: () => import('@/views/dms/OuterListPage.vue' /* webpackChunkName: "outer-list-page" */),
        meta: {
          pageTitle: { en: 'OuterList - NDL Digital Collections', ja: '送信履歴一覧 - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/outer/detail/:id',
        name: 'OuterDetailPage',
        component: () => import('@/views/dms/OuterDetailPage.vue' /* webpackChunkName: "outer-detail-page" */),
        meta: {
          pageTitle: { en: 'OuterDetail - NDL Digital Collections', ja: '送信履歴詳細 - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/outer/item/:pid',
        name: 'OuterItemPage',
        component: () => import('@/views/dms/OuterItemPage.vue' /* webpackChunkName: "outer-item-page" */),
        meta: {
          pageTitle: { en: 'OuterItem - NDL Digital Collections', ja: '送信資料詳細 - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/online',
        name: 'OnlinePage',
        component: () => import('@/views/dms/OnlinePage.vue' /* webpackChunkName: "online-page" */),
        meta: {
          pageTitle: { en: 'Online - NDL Digital Collections', ja: 'オンライン資料納入トップ - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/online/agreement',
        name: 'OnlineAgreementPage',
        component: () => import('@/views/dms/OnlineAgreementPage.vue' /* webpackChunkName: "online-agreement-page" */),
        meta: {
          pageTitle: { en: 'OnlineAgreement - NDL Digital Collections', ja: 'オンライン資料自動収集申出 - 承諾 - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/online/input',
        name: 'OnlineInputPage',
        component: () => import('@/views/dms/OnlineInputPage.vue' /* webpackChunkName: "online-input-page" */),
        meta: {
          pageTitle: { en: 'OnlineInput - NDL Digital Collections', ja: 'オンライン資料自動収集申出 - 入力 - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/online/confirm',
        name: 'OnlineConfirmPage',
        component: () => import('@/views/dms/OnlineConfirmPage.vue' /* webpackChunkName: "online-confirm-page" */),
        meta: {
          pageTitle: { en: 'OnlineConfirm - NDL Digital Collections', ja: 'オンライン資料自動収集申出 - 内容確認 - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/online/complete',
        name: 'OnlineCompletePage',
        component: () => import('@/views/dms/OnlineCompletePage.vue' /* webpackChunkName: "online-complete-page" */),
        meta: {
          pageTitle: { en: 'OnlineComplete - NDL Digital Collections', ja: 'オンライン資料自動収集申出 - 完了 - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/openinq',
        name: 'PublicSearchPage',
        props: true,
        component: () => import('@/views/dms/PublicSearchPage.vue' /* webpackChunkName: "public-search-page" */),
        meta: {
          pageTitle: { en: 'PublicSearch - NDL Digital Collections', ja: '公開調査トップ／検索 - 国立国会図書館デジタルコレクション' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/openinq/search',
        name: 'PublicSearchResultPage',
        component: () => import('@/views/dms/PublicSearchResultPage.vue' /* webpackChunkName: "public-search-result-page" */),
        meta: {
          pageTitle: { en: 'PublicSearchResult - NDL Digital Collections', ja: '公開調査検索結果 - 国立国会図書館デジタルコレクション' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/openinq/searchResult',
        redirect (to) {
          const query: StringKeyObject = {}
          if (to.query.searchWord) query.keyword = to.query.searchWord
          if (to.query.ndc) query.ndc = to.query.ndc
          return { path: '/openinq/search', query }
        },
      },
      {
        path: '/dms/author/detail',
        name: 'AuthorDetailPage',
        component: () => import('@/views/dms/AuthorDetailPage.vue' /* webpackChunkName: "author-detail-page" */),
        meta: {
          pageTitle: { en: 'AuthorDetail - NDL Digital Collections', ja: '著作権情報詳細 - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/author/input',
        name: 'AuthorInputPage',
        component: () => import('@/views/dms/AuthorInputPage.vue' /* webpackChunkName: "author-input-page" */),
        meta: {
          pageTitle: { en: 'AuthorInput - NDL Digital Collections', ja: '著作権情報提供 - 入力 - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/author/confirm',
        name: 'AuthorConfirmPage',
        component: () => import('@/views/dms/AuthorConfirmPage.vue' /* webpackChunkName: "author-confirm-page" */),
        meta: {
          pageTitle: { en: 'AuthorInput - NDL Digital Collections', ja: '著作権情報提供 - 内容確認 - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      {
        path: '/dms/author/complete',
        name: 'AuthorCompletePage',
        component: () => import('@/views/dms/AuthorCompletePage.vue' /* webpackChunkName: "author-complete-page" */),
        meta: {
          pageTitle: { en: 'AuthorComplete - NDL Digital Collections', ja: '著作権情報提供 - 完了 - 国立国会図書館' },
          description: { en: '(description)', ja: '(description)' },
          ogType: 'article',
        },
      },
      // 外部提供者画面エラーハンドリング
      {
        path: '/dms',
        redirect: '/dms/relogin',
      },
    ],
  },
  // リダイレクト設定
  {
    path: '/kindai',
    redirect: '/',
  },
  {
    path: '/kindai/:path(.*)',
    redirect: (to) => {
      return `/${to.params.path}`
    },
  },
  {
    path: '/:static(.*).html',
    redirect: (to) => {
      return to.fullPath.replace('.html', '')
    },
  },
  {
    path: '/info\\:ndljp/pid/:pid(\\d+)/:contentIndex(\\d+)',
    redirect: (to) => {
      const contentIndex = to.params.contentIndex
      return `/pid/${to.params.pid}/1/${contentIndex}`
    },
  },
  {
    path: '/info\\:ndljp/pid/:pid(\\d+)?',
    redirect: (to) => {
      const contentNo = to.query.contentNo ? to.query.contentNo : '1'
      return { path: `/pid/${to.params.pid}/1/${contentNo}`, query: {} }
    },
  },
  // jpegダウンロード
  {
    path: '/view/jpegOutput:itemInfo(.*)',
    redirect: (to) => {
      // itemIdは"info:ndljp/pid/xxxxx"
      const pid = makeUpdatedPidString(to.query.itemId?.toString())
      const contentId = to.query.contentNo ? to.query.contentNo : '1'
      const path = pid ? `/${pid}/1/${contentId}` : '/notFound'
      return { path, query: {} }
    },
  },
  {
    path: '/BIImgFrame.php:searchCriteria(.*)',
    redirect: (to) => {
      const query = to.query.JP_NUM ? { identifierItem: 'JPNO', identifier: to.query.JP_NUM } : {}
      return { path: '/search/searchResult', query }
    },
  },
  // routeの追加はこれより上に行う
  {
    path: '/:static(.*)',
    name: 'StaticPage',
    component: () => import('../views/page/Static.vue' /* webpackChunkName: "static" */),
    meta: {
      pageTitle: { en: 'NDL Digital Collections', ja: '国立国会図書館デジタルコレクション' },
      description: { en: '(description)', ja: '(description)' },
      ogType: 'article',
    },
  },
]

const setI18nAlias = (routes: Array<RouteRecordRaw>): Array<RouteRecordRaw> => {
  return routes.map(route => {
    let children
    if (route.children && route.children.length > 0) {
      children = setI18nAlias(route.children)
    }
    return {
      ...route,
      children,
      alias: ['/ja' + route.path, '/en' + route.path],
    }
  })
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: setI18nAlias(routes),
  scrollBehavior: async (to, from, savedPosition) => {
    const findElement = async (hash: string, times = 0): Promise<Element | undefined> => {
      if (!document) return // サーバーサイド実行時
      if (!hash || hash === '#') return // アンカーリンクがない時
      if (times > 20) return // 20回のリトライで対象要素が見つからない時
      if (times === 0) { // 初回画面遷移時
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(findElement(hash, ++times))
          }, 1500) // 画面が描画され終わるまで待機する
        })
      }

      return document.querySelector(hash) ||
        new Promise((resolve) => {
          setTimeout(() => {
            resolve(findElement(hash, ++times))
          }, 100)
        })
    }

    // findElementは初回1500ms待機するが、ページ内遷移では初回待機させないため
    const isSamePageTransition = !from.name ? false : from.name === to.name
    const targetElement = (await findElement(to.hash, Number(isSamePageTransition)) as HTMLElement | undefined)

    if (savedPosition) {
      if (isSamePageTransition && to.hash === '') {
        // ページトップへ戻る(<a href='#'>~</a>)をクリックした場合
        return { top: 0 }
      } else {
        return savedPosition
      }
    } else if (!targetElement) {
      return { left: 0, top: 0 }
    } else if ('scrollBehavior' in document.documentElement.style) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      return { el: to.hash }
    }
  },
})

const pagesNotHaveEnglishPage = [
  '/dms',
  '/openinq',
]

router.beforeEach(async (to, from, next) => {
  const toTop = () => {
    // セッション切れログインモーダルを表示する間、一時的にトップ画面を表示する。
    next('/')
  }

  // セッションチェックをする
  if (!to.hash && !await sessionCheck(to.fullPath, toTop)) return

  const isJapanesePath = (path: string) => path.startsWith('/ja/') || path === '/ja'
  const isEnglishPath = (path: string) => path.startsWith('/en/') || path === '/en'
  // 英語版のないページでは、fromが/enでtoが/enでない遷移を許容する
  const allowWithoutEn = (path: string) => {
    return pagesNotHaveEnglishPage.some(v => {
      const regexp1 = new RegExp('^' + v + '/\\S*')
      const regexp2 = new RegExp('^' + v + '$')
      const regexp3 = new RegExp('^/ja' + v + '/\\S*')
      const regexp4 = new RegExp('^/ja' + v + '$')
      return path.match(regexp1) || path.match(regexp2) || path.match(regexp3) || path.match(regexp4)
    })
  }
  // 英語版のないページでは、パスの/enを取り除いて遷移する必要がある
  const shouldRemoveEn = (path: string) => {
    return pagesNotHaveEnglishPage.some(v => {
      const regexp1 = new RegExp('^/en' + v + '/\\S*')
      const regexp2 = new RegExp('^/en' + v + '$')
      return path.match(regexp1) || path.match(regexp2)
    })
  }

  // ページごとにメタを設定する
  if (isJapanesePath(to.fullPath)) to.meta.lang = 'ja'
  if (isEnglishPath(to.fullPath)) to.meta.lang = 'en'
  // 必要に応じてURLを書き換えて遷移する
  if (isJapanesePath(from.fullPath) && !isJapanesePath(to.fullPath)) {
    next(isEnglishPath(to.fullPath) ? to.fullPath.replace(/^\/en/, '/ja') : '/ja' + to.fullPath)
    return
  }
  if (isEnglishPath(from.fullPath) && !isEnglishPath(to.fullPath) && !allowWithoutEn(to.fullPath)) {
    next(isJapanesePath(to.fullPath) ? to.fullPath.replace(/^\/ja/, '/en') : '/en' + to.fullPath)
    return
  }
  if (shouldRemoveEn(to.fullPath)) {
    next(to.fullPath.replace(/^\/en/, ''))
    return
  }
  next()
})

// 引数の確認のため、未使用仮引数をあえて記載しています。
// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.afterEach((to, from, failure) => {
  const cardId = getCardId(store)
  const toMeta = createToParameter(to, cardId)
  const fromMeta = createFromParameter(from, cardId)
  const isDuplicate = toMeta.name === 'ContentDetail' && toMeta.fullPath.replace(/^\/ja|^\/en/, '') === fromMeta.fullPath.replace(/^\/ja|^\/en/, '')
  if (isDuplicate) return
  tracker.push({ key: 'router', to: toMeta, from: fromMeta })
  if (cardId != null) {
    tracker.push({ key: 'router', to: toMeta, from: fromMeta, cardId: cardId })
  }
})

export default router
