
import { defineComponent, reactive, ref, watchEffect, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import AppSelect from '../atoms/AppSelect.vue'
import KeywordSearch from '../molecules/KeywordSearch.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import { MUTATIONS } from '@/store/mutations-types'
import TheKnNav from '@/components/organisms/kn/TheKnNav.vue'
import useI18nFunction from '@/helpers/hooks/useI18nFunction'
import { sortList } from '@/data/selector/sortList'
import StringKeyObject from '@/data/@types/StringKeyObject'
import { defaultQuery } from '@/domain/item/SearchRequest'

export default defineComponent({
  components: {
    AppSelect,
    KeywordSearch,
    AppButton,
    TheKnNav,
  },
  props: {
    isRekion: {
      type: Boolean,
      default: false,
    },
    isKn: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const i18nFunction = useI18nFunction()

    const isServer = typeof window === 'undefined'
    const i18n = useI18n()

    const changeLocale = () => {
      const beforeLang = i18n.locale.value
      const afterLang = beforeLang === 'ja' ? 'en' : 'ja'
      if (isServer) return
      // 多言語用のパスはaliasで実現しているため、router.replaceは同一パスに遷移できない
      // そのため、history.replaceStateで実装し、パスを書き換えるためにリロードさせる
      history.replaceState(null, '',
        route.fullPath.startsWith(`/${beforeLang}/`) || route.fullPath === `/${beforeLang}`
          ? route.fullPath.replace(`/${beforeLang}`, `/${afterLang}`)
          : `/${afterLang}` + route.fullPath
      )
      localStorage.language = afterLang
      location.reload()
    }
    const state = reactive<{
      displayMode: 'light' | 'dark';
    }>({
      displayMode: 'dark',
    })

    if (localStorage.theme === 'light') {
      state.displayMode = 'light'
    } else {
      state.displayMode = 'dark'
    }

    watchEffect(() => {
      if (props.isRekion) {
        document.documentElement.classList.add('is-rekion')
        document.documentElement.classList.add('is-light')
        document.documentElement.classList.remove('is-dark')
      } else if (props.isKn) {
        document.documentElement.classList.add('is-kn')
        document.documentElement.classList.add('is-light')
        document.documentElement.classList.remove('is-dark')
      } else if (state.displayMode === 'dark') {
        localStorage.theme = 'dark'
        document.documentElement.classList.add('is-dark')
        document.documentElement.classList.remove('is-light')
        document.documentElement.classList.remove('is-rekion')
      } else {
        localStorage.theme = 'light'
        document.documentElement.classList.add('is-light')
        document.documentElement.classList.remove('is-rekion')
        document.documentElement.classList.remove('is-dark')
      }
      i18nFunction.execFunction()
    })

    const isLoggedIn = computed(() => store.getters.isLoggedIn)
    const loginType = computed(() => store.getters.loginType)
    const isFromKss = computed(() => store.getters.isFromKss)
    const isFromNdl = computed(() => store.getters.isFromNdl)
    const isBuiltin = computed(() => loginType.value === 'builtin')
    const loginUserCardId = computed(() => store.getters.loggedInUser?.cardId)
    const isRekionBuilding = computed(() => store.getters.isRekionBuilding)
    const isPackageUser = computed(() => store.getters.isPackageUser)
    const isLibsend = computed(() => store.getters.isLibsend)
    const libraryName = computed(() => store.getters.libraryName)
    const canLogout = computed(() => {
      // パ系ID
      if (isPackageUser.value) {
        // NDLもしくはKSS端末からアクセスした場合は、ログアウトボタンを表示する。
        if (isFromNdl.value || isFromKss.value) return true
        return false
      }
      // REKION、PATRON、KSS端末の場合は、ログアウトボタンを表示しない。
      if (isRekionBuilding.value || isLibsend.value.includes('PATRON') || isFromKss.value) return false
      // 上記以外はログアウトボタンを表示する。
      return true
    })
    const canStatistiscDownload = computed(() => store.getters.canDownloadStatistics)

    const showStatisticsDownloadLink = computed(() => {
      return loginUserCardId.value === 'X00000001' || canStatistiscDownload.value
    })

    const showKeywordSearch = computed(() => {
      return !(route.name === 'TopPage' || route.name === 'RekionTopPage')
    })

    const keyword = ref<string>('')

    // 検索結果一覧画面の簡易検索と同様の処理
    const search = async () => {
      // 検索中に再度検索が呼び出された場合、実行しない
      const isSearching = store.getters.itemIsProcessing
      if (isSearching) return

      const searchMode = computed(() => store.getters.searchMode)
      const trimmedKeyword = keyword.value.trim()

      const checkedPlaces = ref(
        props.isRekion ? store.getters.getRekionAccessRestrictionsCheckList : store.getters.getAccessRestrictionsCheckList
      )

      const useQuery: StringKeyObject = { ...defaultQuery }
      if (trimmedKeyword) useQuery.keyword = trimmedKeyword
      if (searchMode.value.pageSize) useQuery.pageSize = searchMode.value.pageSize
      if (searchMode.value.sortKey) useQuery.sortKey = searchMode.value.sortKey
      if (searchMode.value.displayMode) useQuery.displayMode = searchMode.value.displayMode
      if (searchMode.value.fullTextInterval) useQuery.fullTextInterval = searchMode.value.fullTextInterval
      if (searchMode.value.ftInterval) useQuery.ftInterval = searchMode.value.ftInterval
      if (checkedPlaces.value.length) useQuery.accessRestrictions = checkedPlaces.value

      store.commit('SET_OPEN_CLOSURE', false)
      if (route.query.keyword === trimmedKeyword && Object.keys(route.query).length === 8) {
        // 前回と同じ検索条件を意図的に検索する（検索ボタンを押す）場合なので、キャッシュは使用しない
        // 詳細検索→ヘッダー簡易検索の場合、同一キーワードでもURLを上書きする必要があるため、クエリの長さで判断する。
        //   ヘッダー簡易検索→ヘッダー簡易検索で同一キーワードの場合はURLが変動しないため、ここでstore.dispatchする。
        const sortObject = sortList.find(e => e.value === searchMode.value.sortKey)
        if (sortObject) {
          useQuery.sortKey = sortObject.sortKey
          useQuery.order = sortObject.order
        }
        await store.dispatch('simpleSearch', useQuery)
      } else {
        const name = props.isRekion
          ? 'RekionSearchResult'
          : 'DetailedSearchResult'
        router.push({
          name,
          query: useQuery,
        })
      }
      keyword.value = ''
    }

    const userStatus = (): string => {
      // props（route.meta）からの取得だと最初isRekionがfalseのためここでisRekionを取得
      const isRekion = process.env.VUE_APP_IS_REKION === 'TRUE'
      if (isLoggedIn.value) {
        if (isFromNdl.value || isFromKss.value) return i18n.t('header.locationLabel.inLibrary')
        else if (isLibsend.value && loginType.value === 'sso') return `${libraryName.value}：${i18n.t('header.locationLabel.inLibtrans')}`
        else if (isRekionBuilding.value && loginType.value === 'builtin' && isRekion) return i18n.t('header.locationLabel.inRekion')
      } else {
        if (isFromNdl.value || isFromKss.value) return i18n.t('header.locationLabel.inLibrary')
      }
      return ''
    }

    const doMyCollection = () => {
      router.push({
        name: 'MyCollection',
        query: {
          pageSize: 20,
          pageNum: 0,
          sortKey: 'SCORE',
          tagId: 0,
          displayMode: 'list',
          displayedItemViewPattern: 'true',
        },
      })
    }

    // TOPページ = true
    const isTopPage = computed(() => {
      return (route.name === 'TopPage')
    })

    // れきおんTOPページ = true
    const isRekionTopPage = computed(() => {
      return (route.name === 'RekionTopPage')
    })

    const focusMove = (id: string) => {
      if (isServer) return
      if (id === 'focus-move-button-of-search-form') {
        // 検索フォームへ移動
        const searchFormElement = document.querySelector('.keyword-search:not(.is-hidden-desktop) input') as HTMLElement
        if (searchFormElement) searchFormElement.focus()
      } else if (id === 'focus-move-button-of-image-search') {
        // 画像検索へ移動
        const imageSearchElement = document.querySelector('.input-image-file-input button') as HTMLElement
        if (imageSearchElement) imageSearchElement.focus()
      } else {
        // コレクションへ移動
        const collectionElement = document.querySelector('.collection-list a') as HTMLElement
        if (collectionElement) collectionElement.focus()
      }
    }

    return {
      state,
      changeLocale,
      doLogout: async () => {
        isLibsend.value ? store.dispatch('logout') : store.dispatch('builtinLogout')
        store.commit(MUTATIONS.LOGOUT_NOTIFICATION, i18n)
      },
      doMyCollection,
      search,
      isLoggedIn,
      isBuiltin,
      keyword,
      showKeywordSearch,
      userStatus,
      canLogout,
      isLibsend,
      showStatisticsDownloadLink,
      isTopPage,
      isRekionTopPage,
      focusMove,
    }
  },
})
