
import { defineComponent, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute, LocationQueryValue } from 'vue-router'
import CloserController from '@/components/molecules/CloserController.vue'
import AppInput from '@/components/atoms/AppInput.vue'
import StringKeyObject from '@/data/@types/StringKeyObject'
import AppButton from '@/components/atoms/AppButton.vue'
import toLocale from '@/helpers/util/toLocale'
import { useI18n } from 'vue-i18n'
import FacetPanelTree from '@/components/organisms/FacetPanelTree.vue'
import { FacetItem } from '@/data/@types/FacetItem'
import { Bucket } from '@/data/@types/Aggregations'
import PublishEra from '@/data/master/PublishEra'
import { getLabel } from '@/data/master/MasterUtil'

type State = {
  ariaSelected: boolean;
};
export default defineComponent({
  name: 'FacetPanel',
  components: {
    CloserController,
    AppInput,
    AppButton,
    FacetPanelTree,
  },
  props: {
    from: {
      type: String,
      required: false,
    },
    to: {
      type: String,
      required: false,
    },
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const i18n = useI18n()
    const lang = i18n.locale

    const state = reactive<State>({
      ariaSelected: true,
    })

    const query = reactive({
      fromYear_facet: computed(() => route.query.fromYear_facet).value,
      toYear_facet: computed(() => route.query.toYear_facet).value,
    })

    const search = () => {
      const where: StringKeyObject = {
        ...route.query,
        ...query,
        pageNum: '0',
      }
      if (!where.fromYear_facet) delete where.fromYear_facet
      if (!where.toYear_facet) delete where.toYear_facet
      // 0詰めしないと正しく検索できないため
      where.fromYear_facet = where.fromYear_facet ? String(where.fromYear_facet).padStart(4, '0') : where.fromYear_facet
      where.toYear_facet = where.toYear_facet ? String(where.toYear_facet).padStart(4, '0') : where.toYear_facet
      router.push({ query: where })
      store.commit('UPDATE_SEARCH_STATE', false)
    }

    const clear = () => {
      const where: StringKeyObject = {
        ...route.query,
        ...query,
        pageNum: '0',
      }
      delete where.publishEra_facet
      delete where.publishYear_facet
      delete where.fromYear_facet
      delete where.toYear_facet
      router.push({ query: where })
    }

    const parseIntForceCatch = (value: string) => {
      try {
        return parseInt(value)
      } catch {
        // ここのエラーは潰す
        return ''
      }
    }

    const clone = (obj: any) => {
      return JSON.parse(JSON.stringify(obj))
    }

    // チェックされた項目を格納
    const checkedFacet = reactive<Array<string>>([])
    const setupFacetBuckets = (queryKey: string, buckets: Array<Bucket>) => {
      buckets.forEach(bucket => {
        let queryValues: Array<string> = Array.from([bucket.keyAsString])
        if (store.getters.narrowSearchQuery[queryKey]) {
          //  既にQueryに存在する場合は、選択状態にする
          bucket.isSelected = Array.from(store.getters.narrowSearchQuery[queryKey]).includes(bucket.keyAsString)
          if (bucket.isSelected) {
            // デジデポ：クエリからファセットの選択状態をチェックボックスに反映する
            const facetQuery = route.query[queryKey]
            if (facetQuery) {
              if (typeof facetQuery !== 'string') {
                facetQuery.forEach((value: LocationQueryValue) => {
                  if (value) {
                    checkedFacet.push(value)
                  }
                })
              } else {
                checkedFacet.push(facetQuery)
              }
            }
          }
          queryValues = Array.from(store.getters.narrowSearchQuery[queryKey])
          queryValues.push(bucket.keyAsString)
        }
        // この時点でqueryの中身は自分のみ || 自分 + 既に検索されているクエリ
        bucket.query = {}
        bucket.query[queryKey] = queryValues
      })
    }

    const facetItem = computed<FacetItem | null>(() => {
      const facetPublishEra = store.getters?.facetList?.publishEra
      const facetPublishYear = store.getters?.facetList?.publishYear
      if (!facetPublishEra && !facetPublishYear) return null
      const eraQueryKey: string = store.getters?.FacetNameQueryMap[facetPublishEra.name]
      //  NOTE: ラベルを用意していない出版年代は画面に表示しない
      const eraBuckets: Array<Bucket> = Array.from<Bucket>(clone(facetPublishEra.buckets))
        .filter(bucket => getLabel(PublishEra, bucket.key, lang.value as 'ja' | 'en'))
      const yearQueryKey: string = store.getters?.FacetNameQueryMap[facetPublishYear.name]
      const yearBuckets: Array<Bucket> = Array.from<Bucket>(clone(facetPublishYear.buckets))

      setupFacetBuckets(eraQueryKey, eraBuckets)
      setupFacetBuckets(yearQueryKey, yearBuckets)

      //  FacetのBuckets構成を、Era > Year の階層構造にする
      yearBuckets.forEach(yb => {
        const year = parseIntForceCatch(yb.keyAsString)
        const parentEra = eraBuckets.find(eb => {
          if (eb.keyAsString === 'unknown') return false
          const keys = eb.keyAsString?.split('-')
          const from = parseIntForceCatch(keys[0])
          const to = parseIntForceCatch(keys[1])
          return from <= year && year <= to
        })
        if (parentEra) {
          if (parentEra.children) {
            parentEra.children.push(yb)
          } else {
            parentEra.children = [yb]
          }
        }
      })
      return new FacetItem(facetPublishEra.name, eraBuckets)
    })

    const composeRouterLink = (bucket: Bucket) => {
      const where: StringKeyObject = {
        ...route.query,
        ...bucket.query,
        pageNum: '0',
      }
      // 選択済みだった場合は逆に絞り込み解除する
      if (bucket.isSelected) {
        //  解除の場合は、出版年代と出版年の条件指定を全て削除する。
        delete where[store.getters.FacetNameQueryMap.publishEra]
        delete where[store.getters.FacetNameQueryMap.publishYear]
      }
      // 簡易検索・詳細検索・コレクションでnameがかわるのでqueryだけ指定する
      return { query: where }
    }

    const searchFacet = (bucket: Bucket) => {
      router.push(composeRouterLink(bucket))
      store.commit('UPDATE_SEARCH_STATE', false)
    }

    const totalHits = computed(() => store.getters.totalHits)

    return {
      route,
      state,
      query,
      fromYear: query.fromYear_facet ? query.fromYear_facet : '',
      toYear: query.toYear_facet ? query.toYear_facet : '',
      totalHits,
      search,
      clear,
      toLocale,
      lang,
      facetItem,
      checkedFacet,
      searchFacet,
    }
  },
})
