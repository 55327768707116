import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, withKeys as _withKeys, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-182b62b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-list-view-media" }
const _hoisted_2 = { class: "level-center is-mobile" }
const _hoisted_3 = ["src", "onerror", "id", "alt"]
const _hoisted_4 = ["id"]
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "item-list-view-detail" }
const _hoisted_7 = { class: "item-list-view-detail-collection has-text-modest has-text-weight-bold" }
const _hoisted_8 = { class: "item-list-view-detail-content" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["id"]
const _hoisted_11 = { class: "the-snippet-text-of-metadata-label" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 1,
  class: "the-snippet-text-of-index-label"
}
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = ["id"]
const _hoisted_17 = {
  key: 0,
  class: "item-list-view-tools has-text-link level is-mobile"
}
const _hoisted_18 = { class: "item-list-view-rights" }
const _hoisted_19 = { class: "icon-area" }
const _hoisted_20 = { class: "item-list-view-permission-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlaceholderImage = _resolveComponent("PlaceholderImage")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CloserController = _resolveComponent("CloserController")!
  const _component_BookMarkButton = _resolveComponent("BookMarkButton")!
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!
  const _component_AppIconPlaces = _resolveComponent("AppIconPlaces")!
  const _component_AppLabelPlaces = _resolveComponent("AppLabelPlaces")!
  const _component_GuideModal = _resolveComponent("GuideModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
      class: _normalizeClass(["item-list-view has-background-contents", `${_ctx.displayMode}-view`])
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_router_link, {
            to: `${_ctx.item.content.pid.replace('info:ndljp', '')}`,
            class: "level-left is-mobile",
            "aria-label": _ctx.$t('label.thumbnail')
          }, {
            default: _withCtx(() => [
              _createElementVNode("figure", _hoisted_2, [
                (_ctx.content.thumbnail && _ctx.hasThumbnail)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.BASE_URL + _ctx.content.thumbnail,
                      onerror: _ctx.notThumbnail,
                      id: 'the-item-list-thumbnail-' + _ctx.itemIndex,
                      alt: _ctx.$t('rightPane.thumbnailAlt')
                    }, null, 8, _hoisted_3))
                  : (_openBlock(), _createBlock(_component_PlaceholderImage, {
                      key: 1,
                      type: _ctx.placeholderType,
                      id: 'the-item-list-icon-' + _ctx.itemIndex
                    }, null, 8, ["type", "id"]))
              ])
            ]),
            _: 1
          }, 8, ["to", "aria-label"])
        ]),
        _createElementVNode("div", {
          id: 'item-list-view-content-' + _ctx.itemIndex,
          class: "item-list-view-content"
        }, [
          _createElementVNode("h2", {
            class: "item-list-view-content-title has-text-table-cell-title",
            id: 'item-list-view-content-title'+_ctx.itemIndex
          }, [
            _createVNode(_component_router_link, {
              to: `/${_ctx.makeUpdatedPidString(_ctx.item.content.pid)}`,
              class: "is-mobile"
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "prefix", {}, undefined, true),
                _createElementVNode("span", null, _toDisplayString(_ctx.itemListViewTitle), 1)
              ]),
              _: 3
            }, 8, ["to"])
          ], 8, _hoisted_5),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.itemListViewCollection), 1),
            _createElementVNode("p", _hoisted_8, [
              _createElementVNode("span", {
                class: "highlight",
                innerHTML: _ctx.itemListViewContent
              }, null, 8, _hoisted_9)
            ])
          ]),
          (_ctx.isSnippetDisplayed)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.snippetsLength > 1)
                  ? (_openBlock(), _createBlock(_component_CloserController, {
                      key: 0,
                      modelValue: _ctx.showSnippet,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showSnippet) = $event)),
                      "aria-controls": `item-list-view-snippet-${_ctx.item.content.pid}`,
                      text: _ctx.closerText,
                      class: _normalizeClass({ 'has-text-link': !_ctx.showSnippet })
                    }, null, 8, ["modelValue", "aria-controls", "text", "class"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: "item-list-view-snippet",
                  id: `item-list-view-snippet-${_ctx.item.content.pid}`
                }, [
                  _createElementVNode("ul", {
                    class: _normalizeClass([{'item-list-view-snippet-open': _ctx.showSnippet}, "has-text-normal-thin"])
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.highlightSnippetArray, (snippet, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        class: _normalizeClass(`snippet-line-${_ctx.displayMode}`),
                        key: `${_ctx.item.content.pid}-snippet-${index}`,
                        id: "the-snippet-text-of-metadata"
                      }, [
                        _createElementVNode("span", _hoisted_11, _toDisplayString(snippet.key) + ": ", 1),
                        _createElementVNode("span", {
                          innerHTML: snippet.value
                        }, null, 8, _hoisted_12)
                      ], 2))
                    }), 128)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTocSnippet, (snippet, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        class: _normalizeClass(`snippet-line-${_ctx.displayMode}`),
                        key: `${_ctx.item.content.pid}-snippet-${index}`,
                        id: "the-snippet-text-of-index"
                      }, [
                        (snippet.path)
                          ? (_openBlock(), _createBlock(_component_router_link, {
                              key: 0,
                              class: "the-snippet-text-of-index-label",
                              to: `/${snippet.path}`
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(snippet.key) + ": ", 1)
                              ]),
                              _: 2
                            }, 1032, ["to"]))
                          : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(snippet.key) + ": ", 1)),
                        _createElementVNode("span", {
                          innerHTML: snippet.value
                        }, null, 8, _hoisted_14)
                      ], 2))
                    }), 128)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fulltextSnippetList, (fulltext, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        class: _normalizeClass(fulltext.multi ? _ctx.showSnippet ? `snippet-line-${_ctx.displayMode}-multi-open` : `snippet-line-${_ctx.displayMode}-multi` : `snippet-line-${_ctx.displayMode}`),
                        id: "the-snippet-text-of-fulltext",
                        key: `${_ctx.item.content.pid}-snippet-${index}`
                      }, [
                        _createVNode(_component_router_link, {
                          class: "the-snippet-text-of-fulltext-label",
                          to: _ctx.getItemParam(fulltext.bundleIndex, fulltext.contentIndex)
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(fulltext.label) + ": ", 1)
                          ]),
                          _: 2
                        }, 1032, ["to"]),
                        _createElementVNode("span", {
                          innerHTML: fulltext.value
                        }, null, 8, _hoisted_15)
                      ], 2))
                    }), 128))
                  ], 2)
                ], 8, _hoisted_10)
              ], 64))
            : _createCommentVNode("", true)
        ], 8, _hoisted_4),
        _createElementVNode("div", {
          id: 'item-list-view-info-'+_ctx.itemIndex,
          class: "item-list-view-info"
        }, [
          (!_ctx.isRekion)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createVNode(_component_BookMarkButton, {
                  pid: _ctx.item.id
                }, null, 8, ["pid"]),
                _renderSlot(_ctx.$slots, "tools", {}, undefined, true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_AppLabelPlaces, { type: _ctx.permissionColor }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_19, [
                  (_ctx.questionmarkFlag)
                    ? (_openBlock(), _createBlock(_component_ButtonIcon, {
                        key: 0,
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isModalShow = true)),
                        onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.isModalShow = true), ["prevent"]), ["space","enter"])),
                        icon: "circle-questionmark",
                        styled: "is-none",
                        class: "questionmark-button",
                        "aria-label": _ctx.$t('rekionGuidance.title')
                      }, null, 8, ["aria-label"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_AppIconPlaces, { type: _ctx.permissionRule }, null, 8, ["type"])
                ]),
                _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.permissionText), 1)
              ]),
              _: 1
            }, 8, ["type"])
          ])
        ], 8, _hoisted_16)
      ]),
      _: 3
    }, 8, ["class"])),
    (_ctx.isModalShow)
      ? (_openBlock(), _createBlock(_component_GuideModal, {
          key: 0,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isModalShow = false))
        }))
      : _createCommentVNode("", true)
  ]))
}