import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-396cbb90"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "viewer-container" }
const _hoisted_2 = {
  key: 0,
  class: "viewer-container-start-button has-background-contents level-center is-mobile shadow"
}
const _hoisted_3 = {
  key: 0,
  class: "viewer-container-fullscreen has-background-modest"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!
  const _component_ViewerPoster = _resolveComponent("ViewerPoster")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.fullTextPinIcon)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "viewer-container-fulltext-button has-background-contents level-center is-mobile shadow",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleFulltextPin && _ctx.toggleFulltextPin(...args)), ["prevent"])),
          onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleFulltextPin && _ctx.toggleFulltextPin(...args)), ["prevent"]), ["space","enter"]))
        }, [
          _createVNode(_component_ButtonIcon, {
            icon: _ctx.fullTextPinIcon,
            styled: "is-none"
          }, null, 8, ["icon"])
        ], 32))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.start && _ctx.start(...args)), ["stop"])),
      onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.start && _ctx.start(...args)), ["prevent","stop"]), ["space","enter"])),
      class: "viewer-container-poster"
    }, [
      _createVNode(_component_ViewerPoster, {
        item: _ctx.item,
        bundleIndex: _ctx.bundleIndex,
        key: _ctx.currentContentIndex + String(_ctx.ischangedToViewerPoster),
        ischangedToViewerPoster: _ctx.ischangedToViewerPoster
      }, null, 8, ["item", "bundleIndex", "ischangedToViewerPoster"]),
      (_ctx.startIcon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ButtonIcon, {
              icon: _ctx.startIcon,
              styled: "is-none",
              "data-cy": "viewer-container-start-button"
            }, null, 8, ["icon"])
          ]))
        : _createCommentVNode("", true)
    ], 32),
    _createVNode(_Transition, { name: "modal" }, {
      default: _withCtx(() => [
        (_ctx.mobileViewerFullscreenFlag)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.viewerType), {
                onClose: _ctx.close,
                key: _ctx.item.pid + _ctx.bundleIndex,
                item: _ctx.item,
                viewerType: _ctx.viewerType,
                bundleIndex: _ctx.bundleIndex,
                "tab-view-flag": _ctx.tabViewFlag,
                class: "viewer-container-body"
              }, null, 8, ["onClose", "item", "viewerType", "bundleIndex", "tab-view-flag"]))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}