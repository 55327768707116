import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21107514"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collection-detail" }
const _hoisted_2 = { class: "collection-detail-body has-background-contents shadow" }
const _hoisted_3 = { class: "collection-detail-show-modal is-hidden-desktop level-center is-mobile has-text-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectionSummary = _resolveComponent("CollectionSummary")!
  const _component_CloserController = _resolveComponent("CloserController")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isRekion)
      ? (_openBlock(), _createBlock(_component_CollectionSummary, {
          key: 0,
          collectionId: _ctx.collectionId,
          class: "shadow"
        }, null, 8, ["collectionId"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
        collectionId: _ctx.collectionId,
        showMobile: _ctx.showMobile,
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMobile = false))
      }, null, 8, ["collectionId", "showMobile"])),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_CloserController, {
          modelValue: _ctx.showMobile,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showMobile) = $event)),
          text: _ctx.$t('label.addSearchCriteria'),
          "aria-controls": "collection-detailed-search-modal-sheet"
        }, null, 8, ["modelValue", "text"])
      ])
    ])
  ]))
}