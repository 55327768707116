interface Master {[key: string]: {ja: string, en: string}}

const publicEra: Master = {
  '0000': { ja: '0000', en: '0000' },
  '0001-0499': { ja: '0001～0499', en: '0001～0499' },
  '0500-0999': { ja: '0500～0999', en: '0500～0999' },
  '1000-1299': { ja: '1000～1299', en: '1000～1299' },
  '1300-1499': { ja: '1300～1499', en: '1300～1499' },
  '1500-1699': { ja: '1500～1699', en: '1500～1699' },
  '1700-1799': { ja: '1700～1799', en: '1700～1799' },
  '1800-1869': { ja: '1800～1869', en: '1800～1869' },
  '1870-1879': { ja: '1870～1879', en: '1870～1879' },
  '1880-1889': { ja: '1880～1889', en: '1880～1889' },
  '1890-1899': { ja: '1890～1899', en: '1890～1899' },
  '1900-1909': { ja: '1900～1909', en: '1900～1909' },
  '1910-1919': { ja: '1910～1919', en: '1910～1919' },
  '1920-1929': { ja: '1920～1929', en: '1920～1929' },
  '1930-1939': { ja: '1930～1939', en: '1930～1939' },
  '1940-1949': { ja: '1940～1949', en: '1940～1949' },
  '1950-1959': { ja: '1950～1959', en: '1950～1959' },
  '1960-1969': { ja: '1960～1969', en: '1960～1969' },
  '1970-1979': { ja: '1970～1979', en: '1970～1979' },
  '1980-1989': { ja: '1980～1989', en: '1980～1989' },
  '1990-1999': { ja: '1990～1999', en: '1990～1999' },
  '2000-2009': { ja: '2000～2009', en: '2000～2009' },
  '2010-2019': { ja: '2010～2019', en: '2010～2019' },
  '2020-2029': { ja: '2020～', en: '2020～' },
  '2030-2039': { ja: '', en: '' },
  unknown: { ja: '出版年不明', en: 'Unknown' },
}

export default publicEra
