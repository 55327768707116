
import { computed, defineComponent, watch, onMounted } from 'vue'
import TheDetailedSearch from '@/components/organisms/TheDetailedSearch.vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { queryConvertToSortKeyAndOrder } from '@/data/SortList'
import { useRequestBuilder } from '@/domain/item/SearchRequest'
import compareObjectRecursive from '@/helpers/util/compareObjectRecursive'
import convertToArray from '@/helpers/util/convertToArray'
import StringKeyObject from '@/data/@types/StringKeyObject'

export default defineComponent({
  name: 'DetailSearchFormPage',
  components: {
    TheDetailedSearch,
  },

  setup () {
    const route = useRoute()
    const store = useStore()

    // TODO make系のリファクタリング
    // 1. undefindだったら**defaultData**、
    // 2. undefindじゃなかったら文字列か判定して、
    //     2-true. 文字列だったら[文字列]
    //     2-false. 文字列じゃないならそのまま配列なはずなのでそのまま利用する
    const makeCollection = (): any[] => {
      const defaultData = store.getters.CollectionValueList
      return route.query.collection ? (typeof route.query.collection === 'string' ? [route.query.collection] : route.query.collection) : defaultData
    }
    const makeFacetCollection = (): any[] => {
      return route.query.collection_facet ? (typeof route.query.collection_facet === 'string' ? [route.query.collection_facet] : route.query.collection_facet) : []
    }
    const makeFacet = (key: string): any[] => {
      const query: StringKeyObject = route.query
      return query[key] ? (typeof query[key] === 'string' ? [query[key]] : query[key]) : []
    }
    const makeNdc = (): any[] => {
      return route.query.ndc ? (typeof route.query.ndc === 'string' ? [route.query.ndc] : route.query.ndc) : []
    }
    const { makeDateRangeQuery } = useRequestBuilder()

    const cutDateParam = (date: string) => {
      const year = date ? date.split('-')[0] : ''
      const month = date ? date.split('-')[1] : ''
      const day = date ? date.split('-')[2] : ''

      // 月、日、年日、月日などのパターンはバリデーションで制御されているので不要
      if (month === '00' && day === '00') return year
      if (day === '00') return year + '-' + month
      return year + '-' + month + '-' + day
    }

    onMounted(() => {
      store.dispatch('selectDisplayCount', route.query.pageSize)
      store.dispatch('selectSortKey', route.query.sortKey)
      store.dispatch('selectDisplayMode', route.query.displayMode)
    })

    const search = async () => {
      const where: StringKeyObject = {
        accessRestrictions: store.getters.getAccessRestrictionsCheckList,
        fullText: true,
        includeVolumeNum: true,
        pageNum: '0',
        pageSize: '20',
        sortKey: 'SCORE',
        order: 'DESC',
      }
      if (route.query.collection) where.collection = makeCollection()
      if (route.query.creator) where.creator = route.query.creator.toString()
      if (route.query.keyword) where.keyword = route.query.keyword.toString()
      if (route.query.publicationPlace) where.publicationPlace = route.query.publicationPlace.toString()
      if (route.query.publisher) where.publisher = route.query.publisher.toString()
      if (route.query.tableOfContents) where.tableOfContents = route.query.tableOfContents.toString()
      if (route.query.title) where.title = route.query.title.toString()
      if (route.query.subject) where.subject = route.query.subject.toString()
      if (route.query.callNumber) where.callNumber = route.query.callNumber.toString()
      if (route.query.ndc) where.ndc = makeNdc()
      if (route.query.ndlc) where.ndlc = route.query.ndlc.toString()
      if (route.query.identifier) where.identifier = route.query.identifier.toString()
      if (route.query.identifierItem) where.identifierItem = route.query.identifierItem ? route.query.identifierItem.toString() : 'ISBN'
      if (route.query.eraType) where.eraType = route.query.eraType ? route.query.eraType.toString() : 'AD'
      const dateQuery = makeDateRangeQuery(route)
      if (Object.keys(dateQuery).length) {
        Object.keys(dateQuery).forEach(key => { where[key] = dateQuery[key] })
      }
      if (route.query.fullText) where.fullText = route.query.fullText === 'true'
      if (route.query.includeVolumeNum) where.includeVolumeNum = route.query.includeVolumeNum === 'true'
      if (route.query.pageNum) where.pageNum = route.query.pageNum.toString()
      store.dispatch('selectDisplayCount', route.query.pageSize)
      store.dispatch('selectSortKey', route.query.sortKey)
      const searchMode = store.getters.searchMode
      where.pageSize = searchMode ? searchMode.pageSize : 20
      where.sortKey = searchMode ? queryConvertToSortKeyAndOrder(searchMode.sortKey).sortKey : 'SCORE'
      where.order = searchMode ? queryConvertToSortKeyAndOrder(searchMode.sortKey).order : 'DESC'
      where.fullTextInterval = searchMode?.fullTextInterval || false
      where.ftInterval = searchMode?.ftInterval || 400
      if (route.query.permission_facet) where.permission_facet = makeFacet('permission_facet')
      if (route.query.ndc_facet) where.ndc_facet = makeFacet('ndc_facet')
      if (route.query.levelCode_facet) where.levelCode_facet = makeFacet('levelCode_facet')
      if (route.query.publishEra_facet) where.publishEra_facet = makeFacet('publishEra_facet')
      if (route.query.publishYear_facet) where.publishYear_facet = makeFacet('publishYear_facet')
      if (route.query.fromYear_facet) where.fromYear_facet = route.query.fromYear_facet.toString()
      if (route.query.toYear_facet) where.toYear_facet = route.query.toYear_facet.toString()
      if (route.query.collection_facet) where.collection_facet = makeFacetCollection()
      if (route.query.itemToSearch_facet) where.itemToSearch_facet = makeFacet('itemToSearch_facet')
      if (route.query.availableFrom) {
        if (route.query.availableType === 'Persend') where.availablePersendFrom = cutDateParam(route.query.availableFrom.toString())
        if (route.query.availableType === 'Internet') where.availableInternetFrom = cutDateParam(route.query.availableFrom.toString())
        if (route.query.availableType === 'Approved') where.availableFrom = cutDateParam(route.query.availableFrom.toString())
      }
      if (route.query.availableTo) {
        if (route.query.availableType === 'Persend') where.availablePersendTo = cutDateParam(route.query.availableTo.toString())
        if (route.query.availableType === 'Internet') where.availableInternetTo = cutDateParam(route.query.availableTo.toString())
        if (route.query.availableType === 'Approved') where.availableTo = cutDateParam(route.query.availableTo.toString())
      }
      await store.dispatch('detailedSearch', where)
    }

    // 1つ前の検索条件保存
    const queryCache = computed(() => store.getters.cacheQuery)

    const checkAndSearch = () => {
      // ガード節 （before/afterの切り替えで2重に検索することがあるため）
      if (route.name !== 'DetailedSearchResult') return
      // 検索条件が同じ場合は検索が行われない
      if (Object.keys(route.query).length && compareObjectRecursive(queryCache.value, route.query)) return
      store.commit('CACHE_QUERY', {
        ...route.query,
      })
      // 公開範囲：チェック情報保持
      const queryAccessRestrictions = route.query.accessRestrictions ? convertToArray(route.query.accessRestrictions) : store.getters.getAccessRestrictionsCheckList
      store.commit('SET_ACCESS_RESTRICTIONS', queryAccessRestrictions)
      search()
    }

    watch(route, () => {
      checkAndSearch()
    })
    checkAndSearch()
    return {
      search,
    }
  },
})
