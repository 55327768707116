import { PhysicalRect, dpx2csspx, DeviceInfo } from './ImageViewerCommon'
import { Pointer, PointerManager } from './PointerManager'
import { UserInputManager } from './UserInputManager'

class CroppingFrame {
  public static get EDGE_WIDTH (): string {
    return '3px'
  }

  private frameRoot_?: HTMLDivElement
  private frameEdgeBG_?: HTMLDivElement
  private frameInnerRect_?: HTMLDivElement
  private frameEdgeRoot_?: HTMLDivElement

  private frameBackGroundStyle_ = `
    position: absolute;
    background-color: transparent;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  `

  private frameInnderRectStyle_ = `
    position: absolute;
    background-color: white;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
  `

  private frameEdgeRootStyle_ = `
    position: absolute;
    background-color: transparent;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    overflow: hidden;
  `

  private frameEdgeBGStyle_ = `
    position: absolute;
    background-color: transparent;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    display: flex;
    justify-content: center;
  `

  private sizeLabel_?: HTMLParagraphElement

  private sizeLabelDivStyle_ = `
    position: absolute;
    background-color: transparent;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `

  private sizeLabelStyle_ = `
    text-align: center;
    color: rgb(255, 255, 255, 0.8);
    font-weight: bold;
    line-height: 1.5rem;
    font-size: 1.5rem;
    text-shadow:
      2px  2px 0 #0c3354,
      -2px -2px 0 #0c3354,  
      2px -2px 0 #0c3354,
      -2px  2px 0 #0c3354,
      2px  2px 0 #0c3354;
  `

  private frameMain_?: HTMLDivElement
  private frameTopCorner_?: HTMLDivElement
  private frameInsideTopCorner_?: HTMLDivElement
  private frameBottomCorner_?: HTMLDivElement
  private frameInsideBottomCorner_?: HTMLDivElement
  private frameEdge_?: HTMLDivElement
  private frameSelectedCorner_?: HTMLDivElement

  /**
   * フレームのボーダーサイズ px
   */
  private edgeSize = 3

  /**
   * フレームコーナーのサイズ px
   */
  private cornerSize = 15

  /**
   * フレームコーナーのマージン px
   */
  private cornerMarginSize = -5

  /**
   * フレームコーナー（内側）のサイズ px
   */
  private insideCornerSize = 10

  /**
  * フレームコーナー（内側）の左右のマージン px
  */
  private insideCornerRLMargin = -13

  /**
  * フレームコーナー（内側）の上のマージン px
  */
  private insideCornerTopMargin = 3

  /**
   * 現在選択されているコーナー "LT", "RT", "LB", "RB" もしくは undefined
   */
  private currentSelectedCorner?: string = undefined

  private frameMainStyle_ = `
    position: absolute;
    background-color: transparent;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; 
  `

  private frameEdgeStyle_ = `
    position: absolute;
    background-color: transparent;
    z-index: inherit;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 3px solid #3F8CD6;
    display: flex;
    justify-content: space-between;
  `

  private frameCornerContainerStyle_ = `
    position: absolute;
    background-color: transparent;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    `

    private frameTopCornerRowStyle_ = `
    position: relative;
    height: 15px;
    margin-left: -5px;
    margin-right: -5px;
    margin-top: -5px;
    background-color: transparent;
    border-left: 15px solid #3F8CD6;
    border-right: 15px solid #3F8CD6;
    z-index: inherit
  `
  private frameTopCornerRowInsideStyle_ = `
    position: relative;
    height: 10px;
    margin-left: -13px;
    margin-right: -13px;
    margin-top: 3px;
    background-color: transparent;
    border-left: 11px solid #ffffff;
    border-right: 11px solid #ffffff;
    z-index: 1
  `

  private frameBottomCornerRowStyle_ = `
    position: relative;
    height: 15px;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: -5px;
    background-color: transparent;
    border-left: 15px solid #3F8CD6;
    border-right: 15px solid #3F8CD6;
    z-index: 1;
  `
  private frameBottomCornerRowInsideStyle_ = `
    position: relative;
    height: 10px;
    margin-left: -13px;
    margin-right: -13px;
    margin-top: 3px;
    background-color: transparent;
    border-left: 11px solid #ffffff;
    border-right: 11px solid #ffffff;
    z-index: 1
    `

  /**
   *
   * @param styleScope vueが付与する style の scope（data-v-XXXXX）
   */
  public constructor (styleScope?: string) {
    this.init(styleScope)
  }

  private init (styleScope?: string): void {
    this.frameRoot_ = document.createElement('div')
    this.frameRoot_.className = 'frame-root'
    this.frameRoot_.style.cssText = this.frameBackGroundStyle_

    this.frameInnerRect_ = document.createElement('div')
    this.frameInnerRect_.className = 'frame-inner-rect'
    this.frameInnerRect_.style.cssText = this.frameInnderRectStyle_
    this.frameRoot_.appendChild(this.frameInnerRect_)

    this.frameEdgeRoot_ = document.createElement('div')
    this.frameEdgeRoot_.className = 'frame-edge-root'
    this.frameEdgeRoot_.style.cssText = this.frameEdgeRootStyle_
    // this.frameRoot_.appendChild(this.frameEdgeRoot_)

    this.frameEdgeBG_ = document.createElement('div')
    this.frameEdgeBG_.className = 'frame-edge-bg'
    this.frameEdgeBG_.style.cssText = this.frameEdgeBGStyle_
    this.frameEdgeRoot_.appendChild(this.frameEdgeBG_)

    const labelDiv = document.createElement('div')
    labelDiv.className = 'label-div'
    labelDiv.style.cssText = this.sizeLabelDivStyle_

    this.sizeLabel_ = document.createElement('p')
    this.sizeLabel_.className = 'size-label'
    this.sizeLabel_.style.cssText = this.sizeLabelStyle_
    const labelText = document.createTextNode('')
    this.sizeLabel_.appendChild(labelText)

    labelDiv.appendChild(this.sizeLabel_)
    this.frameEdgeBG_.appendChild(labelDiv)

    this.frameMain_ = document.createElement('div')
    this.frameMain_.className = 'frame-main'
    this.frameMain_.style.cssText = this.frameMainStyle_

    this.frameEdgeBG_.appendChild(this.frameMain_)

    const cc = document.createElement('div')
    cc.className = 'cc'
    cc.style.cssText = this.frameCornerContainerStyle_
    this.frameEdgeBG_.appendChild(cc)

    this.frameEdge_ = document.createElement('div')
    this.frameEdge_.className = 'edge'
    this.frameEdge_.style.cssText = this.frameEdgeStyle_
    this.frameEdgeBG_.appendChild(this.frameEdge_)

    this.frameTopCorner_ = document.createElement('div')
    this.frameTopCorner_.className = 'top-corner'
    this.frameTopCorner_.style.cssText = this.frameTopCornerRowStyle_
    cc.appendChild(this.frameTopCorner_)

    this.frameInsideTopCorner_ = document.createElement('div')
    this.frameInsideTopCorner_.className = 'inside-top-corner'
    this.frameInsideTopCorner_.style.cssText = this.frameTopCornerRowInsideStyle_
    this.frameTopCorner_.appendChild(this.frameInsideTopCorner_)

    this.frameBottomCorner_ = document.createElement('div')
    this.frameBottomCorner_.className = 'bottom-corner'
    this.frameBottomCorner_.style.cssText = this.frameBottomCornerRowStyle_
    cc.appendChild(this.frameBottomCorner_)

    this.frameInsideBottomCorner_ = document.createElement('div')
    this.frameInsideBottomCorner_.className = 'inside-bottom-corner'
    this.frameInsideBottomCorner_.style.cssText = this.frameBottomCornerRowInsideStyle_
    this.frameBottomCorner_.appendChild(this.frameInsideBottomCorner_)

    this.frameSelectedCorner_ = document.createElement('div')
    this.frameSelectedCorner_.className = 'selected_cropping_frame_corner'
    cc.appendChild(this.frameSelectedCorner_)
    if (styleScope) {
      this.frameSelectedCorner_.setAttribute(styleScope, '')
    }
  }

  public injectTo (parent: HTMLElement, beforNode?: Node | null): void {
    if (this.frameRoot_ && this.frameEdgeRoot_ && parent.parentElement) {
      parent.appendChild(this.frameRoot_)
      parent.parentElement.insertBefore(this.frameEdgeRoot_, beforNode || null)
    }
  }

  public setFrameCoordinate (rect: PhysicalRect): void {
    if (this.frameEdgeBG_ && this.frameInnerRect_ && this.frameMain_) {
      let rs = this.frameInnerRect_.style
      rs.left = (rect.left / DeviceInfo.DPR) + 'px'
      rs.top = (rect.top / DeviceInfo.DPR) + 'px'
      rs.width = (rect.width / DeviceInfo.DPR) + 'px'
      rs.height = (rect.height / DeviceInfo.DPR) + 'px'

      rs = this.frameEdgeBG_.style
      rs.left = (rect.left / DeviceInfo.DPR) + 'px'
      rs.top = (rect.top / DeviceInfo.DPR) + 'px'
      rs.width = (rect.width / DeviceInfo.DPR) + 'px'
      rs.height = (rect.height / DeviceInfo.DPR) + 'px'

      this.updateSelectedCorner()
    }
  }

  /**
   * フレームラベルの内容を変更する
   * @param text
   */
  public setLabelText (text?: string): void {
    if (this.sizeLabel_) {
      if (this.sizeLabel_.childNodes[0]) {
        this.sizeLabel_.removeChild(this.sizeLabel_.childNodes[0])
      }
      if (text) { this.sizeLabel_.appendChild(document.createTextNode(text)) }
    }
  }

  /**
   * フレームを渡す
   */
  public getFrameRoot (): HTMLDivElement | undefined {
    if (this.frameRoot_) { return this.frameRoot_ }
  }

  public getFrameEdgeRoot (): HTMLDivElement | undefined {
    if (this.frameEdgeRoot_) { return this.frameEdgeRoot_ }
  }

  /**
   * ブラウザの拡大縮小によって、フレームの線の太さが変わらないようにリサイズする
   */
  public resizeFrameStyle (): void {
    if (this.frameEdge_ && this.frameTopCorner_ && this.frameInsideTopCorner_ && this.frameBottomCorner_ && this.frameInsideBottomCorner_ && this.sizeLabel_) {
      // フレームの線
      this.frameEdge_.style.border = dpx2csspx(this.edgeSize) + 'px ' + 'solid #3F8CD6'

      // フレームのコーナー（上）
      this.frameTopCorner_.style.height = dpx2csspx(this.cornerSize) + 'px'
      this.frameTopCorner_.style.marginLeft = dpx2csspx(this.cornerMarginSize) + 'px'
      this.frameTopCorner_.style.marginRight = dpx2csspx(this.cornerMarginSize) + 'px'
      this.frameTopCorner_.style.marginTop = dpx2csspx(this.cornerMarginSize) + 'px'
      this.frameTopCorner_.style.borderLeft = dpx2csspx(this.cornerSize) + 'px ' + 'solid #3F8CD6'
      this.frameTopCorner_.style.borderRight = dpx2csspx(this.cornerSize) + 'px ' + 'solid #3F8CD6'

      this.frameInsideTopCorner_.style.height = dpx2csspx(this.insideCornerSize) + 'px'
      this.frameInsideTopCorner_.style.marginLeft = dpx2csspx(this.insideCornerRLMargin) + 'px'
      this.frameInsideTopCorner_.style.marginRight = dpx2csspx(this.insideCornerRLMargin) + 'px'
      this.frameInsideTopCorner_.style.marginTop = dpx2csspx(this.insideCornerTopMargin) + 'px'
      this.frameInsideTopCorner_.style.borderLeft = dpx2csspx(this.insideCornerSize) + 'px ' + 'solid #ffffff'
      this.frameInsideTopCorner_.style.borderRight = dpx2csspx(this.insideCornerSize) + 'px ' + 'solid #ffffff'

      // フレームのコーナー（下）
      this.frameBottomCorner_.style.height = dpx2csspx(this.cornerSize) + 'px'
      this.frameBottomCorner_.style.marginLeft = dpx2csspx(this.cornerMarginSize) + 'px'
      this.frameBottomCorner_.style.marginRight = dpx2csspx(this.cornerMarginSize) + 'px'
      this.frameBottomCorner_.style.marginBottom = dpx2csspx(this.cornerMarginSize) + 'px'
      this.frameBottomCorner_.style.borderLeft = dpx2csspx(this.cornerSize) + 'px ' + 'solid #3F8CD6'
      this.frameBottomCorner_.style.borderRight = dpx2csspx(this.cornerSize) + 'px ' + 'solid #3F8CD6'

      this.frameInsideBottomCorner_.style.height = dpx2csspx(this.insideCornerSize) + 'px'
      this.frameInsideBottomCorner_.style.marginLeft = dpx2csspx(this.insideCornerRLMargin) + 'px'
      this.frameInsideBottomCorner_.style.marginRight = dpx2csspx(this.insideCornerRLMargin) + 'px'
      this.frameInsideBottomCorner_.style.marginTop = dpx2csspx(this.insideCornerTopMargin) + 'px'
      this.frameInsideBottomCorner_.style.borderLeft = dpx2csspx(this.insideCornerSize) + 'px ' + 'solid #ffffff'
      this.frameInsideBottomCorner_.style.borderRight = dpx2csspx(this.insideCornerSize) + 'px ' + 'solid #ffffff'
    }
  }

  public updateSelectedCorner () {
    if (!this.currentSelectedCorner) {
      return
    }

    if (!this.frameEdgeBG_ || !this.frameSelectedCorner_) {
      return
    }

    let left = 0
    let top = 0

    const elemWidth = this.frameSelectedCorner_.offsetWidth
    const elemHeight = this.frameSelectedCorner_.offsetHeight

    switch (this.currentSelectedCorner) {
      case 'LT':
        left = -elemWidth / 2 + this.edgeSize / 2
        top = -elemHeight / 2 + this.edgeSize / 2
        break
      case 'RT':
        left = this.frameEdgeBG_.offsetWidth - elemWidth / 2 - this.edgeSize / 2
        top = -elemHeight / 2 + this.edgeSize / 2
        break
      case 'LB':
        left = -elemWidth / 2 + this.edgeSize / 2
        top = this.frameEdgeBG_.offsetHeight - elemHeight / 2 - this.edgeSize / 2
        break
      case 'RB':
        left = this.frameEdgeBG_.offsetWidth - elemWidth / 2 - this.edgeSize / 2
        top = this.frameEdgeBG_.offsetHeight - elemHeight / 2 - this.edgeSize / 2
        break
    }

    this.frameSelectedCorner_.style.left = left + 'px'
    this.frameSelectedCorner_.style.top = top + 'px'
  }

  public selectComponent (component?: string) {
    if (!this.frameEdgeBG_ || !this.frameSelectedCorner_) {
      return
    }

    if (this.currentSelectedCorner === component?.toUpperCase()) {
      return
    }

    if (!component) {
      this.frameSelectedCorner_.style.visibility = ''
      this.currentSelectedCorner = undefined

      if (this.frameEdge_) {
        this.frameEdge_.style.borderStyle = 'solid'
        this.frameEdge_.style.borderWidth = this.edgeSize + 'px'
      }
    } else {
      const comp = component.toUpperCase()
      if (comp === 'LT' || comp === 'RT' || comp === 'LB' || comp === 'RB') {
        this.frameSelectedCorner_.style.visibility = 'visible'
        this.currentSelectedCorner = comp

        if (this.frameEdge_) {
          this.frameEdge_.style.borderStyle = 'solid'
          this.frameEdge_.style.borderWidth = this.edgeSize + 'px'
        }

        this.updateSelectedCorner()
      } else {
        this.frameSelectedCorner_.style.visibility = ''

        this.currentSelectedCorner = comp
        if (comp === 'ALL' && this.frameEdge_) {
          this.frameEdge_.style.borderStyle = 'double'
          this.frameEdge_.style.borderWidth = (this.edgeSize * 3) + 'px'
        }
      }
    }
  }
}

const FrameComponent = {
  OUTSIDE: 0,

  EDGE_LEFT: 1,
  EDGE_TOP: 2,
  EDGE_RIGHT: 3,
  EDGE_BOTTOM: 4,

  CORNER_LT: 5,
  CORNER_RT: 6,
  CORNER_LB: 7,
  CORNER_RB: 8,

  INSIDE: 9,

  ALL: 10,
} as const

type FrameComponent = typeof FrameComponent[keyof typeof FrameComponent]

class PrintCroppingLayer {
  /**
   * 切り抜きフレームの位置とサイズ
   */
  private frameRect_ = new PhysicalRect()
  private frameRectBuf_ = new PhysicalRect()

  private rootElement_?: HTMLDivElement

  private frame_?: CroppingFrame

  private enabled_ = false

  private edgeDetectSize_ = 10

  private editable_ = true
  public set editable (value: boolean) {
    this.editable_ = value
  }

  /**
   * フレームが描画されているか
   */
  private frameIsDrawn_ = false
  public set frameIsDrawn (value: boolean) {
    this.frameIsDrawn_ = value
  }

  public get frameIsDrawn (): boolean {
    return this.frameIsDrawn_
  }

  /**
   * 最小の領域幅
   */
  private static get AREA_SIZE_MIN_LIMIT (): number {
    return 50
  }

  private valueToPreventPositionShifting_ = 40

  private onFrameChangeListener_?: (rect: PhysicalRect) => void

  private rootStyle_ = `
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      mix-blend-mode: multiply;
  `

  private frameDragging_ = false
  private currentFrameConponent_: FrameComponent = FrameComponent.OUTSIDE

  /**
   *
   * @param inputMnager
   * @param styleScope vueが付与する style の scope（data-v-XXXXX）
   */
  public constructor (inputMnager: UserInputManager, styleScope?: string) {
    this.frame_ = new CroppingFrame(styleScope)
    this.init(inputMnager)
  }

  /**
   * メインキャンバスのリサイズ監視
   */
  // private resizeObserver_?: ResizeObserver;

  private init (inputMnager: UserInputManager) {
    this.rootElement_ = document.createElement('div')
    this.rootElement_.className = 'crop-bg-element'
    this.rootElement_.style.cssText = this.rootStyle_

    const layerIndex = 1
    inputMnager.attachEventListener(layerIndex, 'mousedown', (event, pm) => this.pointerDown(event, pm))
    inputMnager.attachEventListener(layerIndex, 'mousemove', (event, pm) => this.pointerMove(event, pm))
    inputMnager.attachEventListener(layerIndex, 'mouseup', (event, pm) => this.pointerUp(event, pm))
    inputMnager.attachEventListener(layerIndex, 'touchstart', (event, pm) => this.pointerDown(event, pm))
    inputMnager.attachEventListener(layerIndex, 'touchmove', (event, pm) => this.pointerMove(event, pm))
    inputMnager.attachEventListener(layerIndex, 'touchend', (event, pm) => this.pointerUp(event, pm))
    inputMnager.attachEventListener(layerIndex, 'click', (event, pm) => this.onClick(event, pm))

    // this.resizeObserver_ = new ResizeObserver(() => {
    //   this.frame_.resizeFrameStyle()
    // })
    // this.resizeObserver_.observe(this.rootElement_)

    this.enabled = false
  }

  public injectTo (element: HTMLElement, beforNode?: Node): void {
    if (this.rootElement_) {
      element.insertBefore(this.rootElement_, beforNode || null)
      // const cover = element.querySelector('#to-cover-viewer')
      if (this.frame_) this.frame_.injectTo(this.rootElement_, beforNode)
    }
  }

  public setOnFrameChnageListner (listener: (rect: PhysicalRect) => void): void {
    this.onFrameChangeListener_ = listener
  }

  public removeOnFrameChangeListener (): void {
    this.onFrameChangeListener_ = undefined
  }

  public dispose (): void{
    // if (this.resizeObserver_) {
    //   this.resizeObserver_.disconnect()
    //   this.resizeObserver_ = undefined
    // }

  }

  public resize (): void {
    if (this.frame_) this.frame_.resizeFrameStyle()
  }

  /**
   *
   * @param left 実ピクセル
   * @param top 実ピクセル
   * @param right 実ピクセル
   * @param bottom 実ピクセル
   * @param dpi 画像のDPI
   * @param pageScale 画像の表示倍率
   * @returns
   */
  public setFrameCoordinate (left: number, top: number, right: number, bottom: number /*, dpi: number, pageScale: number */): boolean {
    this.frameRectBuf_.left = left
    this.frameRectBuf_.top = top
    if (right) {
      this.frameRectBuf_.right = right
    }
    if (bottom) {
      this.frameRectBuf_.bottom = bottom
    }

    if (this.frameRectBuf_.left > this.frameRectBuf_.right) {
      this.frameRectBuf_.right = this.frameRectBuf_.left
    }
    if (this.frameRectBuf_.top > this.frameRectBuf_.bottom) {
      this.frameRectBuf_.bottom = this.frameRectBuf_.top
    }

    this.frameRect_.copy(this.frameRectBuf_)
    if (this.frame_) {
      this.frame_.setFrameCoordinate(this.frameRect_)
      const frameRoot = this.frame_.getFrameRoot()
      if (frameRoot) {
        frameRoot.style.display = ''
      }
      const frameEdgeRoot = this.frame_.getFrameEdgeRoot()
      if (frameEdgeRoot) {
        frameEdgeRoot.style.display = ''
      }
    }
    return true
  }

  public set enabled (value: boolean) {
    this.enabled_ = value

    if (this.rootElement_ && this.frame_) {
      if (this.enabled_) {
        this.rootElement_.style.display = ''
        const frameRoot = this.frame_.getFrameRoot()
        if (frameRoot) {
          // frameRootの高さか幅が0の場合は選択領域のフレームを見えなくする
          if (frameRoot.offsetHeight === 0 || frameRoot.offsetWidth === 0) {
            frameRoot.style.display = 'none'
          }
        }
        const frameEdgeRoot = this.frame_.getFrameEdgeRoot()
        if (frameEdgeRoot) {
          // frameRootの高さか幅が0の場合は選択領域のフレームを見えなくする
          if (frameEdgeRoot.offsetHeight === 0 || frameEdgeRoot.offsetWidth === 0) {
            frameEdgeRoot.style.display = 'none'
          } else {
            frameEdgeRoot.style.display = 'block'
          }
        }
      } else {
        this.rootElement_.style.display = 'none'
        const frameEdgeRoot = this.frame_.getFrameEdgeRoot()
        if (frameEdgeRoot) {
          frameEdgeRoot.style.display = 'none'
        }
      }

      if (!value) {
        this.selectComponent()
      }
    }
  }

  public get enabled (): boolean {
    return this.enabled_
  }

  private fireOnFrameChangeEvent (): void {
    if (this.enabled && this.onFrameChangeListener_) {
      this.frameRectBuf_.left = this.frameRect_.left
      this.frameRectBuf_.top = this.frameRect_.top
      this.frameRectBuf_.right = this.frameRect_.right
      this.frameRectBuf_.bottom = this.frameRect_.bottom

      this.onFrameChangeListener_(this.frameRectBuf_)
    }
  }

  private pointerDown (event: MouseEvent | TouchEvent, pointerManager: PointerManager): boolean {
    if (!this.enabled_) {
      return false
    }

    const fc = this.detectPointingComponentOfFrame(pointerManager, pointerManager.getLastPointer())

    this.frameDragging_ = false
    this.currentFrameConponent_ = fc

    if (this.currentFrameConponent_ !== FrameComponent.OUTSIDE) {
      event.preventDefault()
      event.stopPropagation()

      this.frameDragging_ = true

      return true
    } else {
      const frameRoot = this.frame_?.getFrameRoot()
      if (!this.frameIsDrawn) {
        this.frameDragging_ = true
        this.currentFrameConponent_ = FrameComponent.CORNER_RB
        if (frameRoot) {
          frameRoot.style.display = ''
        }
        const frameEdgeRoot = this.frame_?.getFrameEdgeRoot()
        if (frameEdgeRoot) {
          frameEdgeRoot.style.display = 'block'
        }
        if (event instanceof MouseEvent) {
          const pt = pointerManager.getPointer(PointerManager.MOUSE_IDENTIFIER)
          if (pt) {
            this.setFrameCoordinate(pt.startPosition.x, pt.startPosition.y, pt.startPosition.x + this.valueToPreventPositionShifting_, pt.startPosition.y + this.valueToPreventPositionShifting_)
          }
          this.removeFrameLabel()
          pointerManager.setToCoverViewerCursor('grabbing')
          this.frameIsDrawn_ = true
        } else {
          const lpt = pointerManager.getLastPointer()
          if (lpt) {
            this.setFrameCoordinate(lpt.startPosition.x, lpt.startPosition.y, lpt.startPosition.x + this.valueToPreventPositionShifting_, lpt.startPosition.y + this.valueToPreventPositionShifting_)
          }
          this.removeFrameLabel()
          pointerManager.setToCoverViewerCursor('grabbing')
          this.frameIsDrawn_ = true
        }
      }
    }

    return false
  }

  private pointerMove (event: MouseEvent | TouchEvent, pointerManager: PointerManager): boolean {
    if (!this.enabled_) {
      return false
    }

    const lastPointer = pointerManager.getLastPointer()

    let fc = this.detectPointingComponentOfFrame(pointerManager, lastPointer)

    if (this.frameDragging_) {
      fc = this.currentFrameConponent_
    }

    if (lastPointer?.enable) {
      if (this.frameDragging_ && fc !== FrameComponent.OUTSIDE) {
        event.preventDefault()
        event.stopPropagation()

        switch (fc) {
          case FrameComponent.EDGE_LEFT:
          case FrameComponent.EDGE_TOP:
          case FrameComponent.EDGE_RIGHT:
          case FrameComponent.EDGE_BOTTOM:
            this.moveFrameEdge(lastPointer, fc)
            break
          case FrameComponent.CORNER_LT:
          case FrameComponent.CORNER_RT:
          case FrameComponent.CORNER_LB:
          case FrameComponent.CORNER_RB:
            this.moveFrameCorner(lastPointer, fc)
            break
          case FrameComponent.INSIDE:
            this.moveFrame(lastPointer)
            this.currentFrameConponent_ = FrameComponent.INSIDE
            break
        }

        this.frameDragging_ = true
        this.selectComponent()
        this.fireOnFrameChangeEvent()
      } else {
        pointerManager.setToCoverViewerCursor('grabbing')
      }
    } else {
      this.frameDragging_ = false
    }

    return this.frameDragging_ || fc !== FrameComponent.OUTSIDE
  }

  private moveFrame (pointer: Pointer): void {
    if (!this.frame_) {
      return
    }
    const moveX = pointer.differenceOfPosition.x
    const moveY = pointer.differenceOfPosition.y

    this.frameRect_.left += moveX
    this.frameRect_.top += moveY
    this.frameRect_.right += moveX
    this.frameRect_.bottom += moveY

    this.frame_.setFrameCoordinate(this.frameRect_)

    this.currentFrameConponent_ = FrameComponent.OUTSIDE
  }

  private moveFrameEdge (pointer: Pointer, fc: FrameComponent): void {
    if (!this.editable_ || !this.frame_) {
      return
    }
    this.frameRectBuf_.copy(this.frameRect_)

    const diffX = pointer.differenceOfPosition.x
    const diffY = pointer.differenceOfPosition.y

    const beforeLeft = this.frameRectBuf_.left
    const beforeTop = this.frameRectBuf_.top
    const beforeRight = this.frameRectBuf_.right
    const beforeBottom = this.frameRectBuf_.bottom
    switch (fc) {
      case FrameComponent.EDGE_LEFT:
        this.frameRectBuf_.left += diffX
        if (this.frameRectBuf_.width < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
          this.frameRectBuf_.left -= diffX
        }
        break
      case FrameComponent.EDGE_TOP:
        this.frameRectBuf_.top += diffY
        if (this.frameRectBuf_.height < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
          this.frameRectBuf_.top -= diffY
        }
        break
      case FrameComponent.EDGE_RIGHT:
        this.frameRectBuf_.right += diffX
        if (this.frameRectBuf_.width < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
          this.frameRectBuf_.right -= diffX
        }
        break
      case FrameComponent.EDGE_BOTTOM:
        this.frameRectBuf_.bottom += diffY
        if (this.frameRectBuf_.height < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
          this.frameRectBuf_.bottom -= diffY
        }
        break
    }

    if (this.frameRectBuf_.left > this.frameRectBuf_.right) {
      if (fc === FrameComponent.EDGE_LEFT) {
        this.frameRectBuf_.left = this.frameRectBuf_.right
      } else {
        this.frameRectBuf_.right = this.frameRectBuf_.left
      }
    }

    if (this.frameRectBuf_.top > this.frameRectBuf_.bottom) {
      if (fc === FrameComponent.EDGE_TOP) {
        this.frameRectBuf_.top = this.frameRectBuf_.bottom
      } else {
        this.frameRectBuf_.bottom = this.frameRectBuf_.top
      }
    }

    if (beforeLeft !== this.frameRectBuf_.left ||
      beforeTop !== this.frameRectBuf_.top ||
      beforeRight !== this.frameRectBuf_.right ||
      beforeBottom !== this.frameRectBuf_.bottom) {
      this.removeFrameLabel()
    }

    this.frameRect_.copy(this.frameRectBuf_)

    this.frame_.setFrameCoordinate(this.frameRect_)
  }

  public moveFrameComponentDirectory (corner: string, direction: string, amount: number) {
    if (!this.editable_ || !this.frame_) {
      return
    }

    this.frameRectBuf_.copy(this.frameRect_)

    const beforeLeft = this.frameRectBuf_.left
    const beforeTop = this.frameRectBuf_.top
    const beforeRight = this.frameRectBuf_.right
    const beforeBottom = this.frameRectBuf_.bottom

    let fc: FrameComponent = FrameComponent.OUTSIDE
    switch (corner.toUpperCase()) {
      case 'LT':
        fc = FrameComponent.CORNER_LT
        break
      case 'RT':
        fc = FrameComponent.CORNER_RT
        break
      case 'LB':
        fc = FrameComponent.CORNER_LB
        break
      case 'RB':
        fc = FrameComponent.CORNER_RB
        break
      case 'ALL':
        fc = FrameComponent.ALL
    }
    if (fc === FrameComponent.OUTSIDE) {
      return
    }

    const d = direction.toUpperCase()

    switch (fc) {
      case FrameComponent.CORNER_LT:
        if (this.frameRectBuf_.height < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT || this.frameRectBuf_.width < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
          if (d === 'L') {
            this.frameRectBuf_.left -= amount
          } else if (d === 'T') {
            this.frameRectBuf_.top -= amount
          } else if (d === 'R') {
            this.frameRectBuf_.left += amount
          } else if (d === 'B') {
            this.frameRectBuf_.top += amount
          }
        } else {
          if (d === 'L') {
            this.frameRectBuf_.left -= amount
          } else if (d === 'T') {
            this.frameRectBuf_.top -= amount
          } else if (d === 'R') {
            this.frameRectBuf_.left += amount
            if (this.frameRectBuf_.width < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
              this.frameRectBuf_.left -= amount
            }
          } else if (d === 'B') {
            this.frameRectBuf_.top += amount
            if (this.frameRectBuf_.height < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
              this.frameRectBuf_.top -= amount
            }
          }
        }
        break
      case FrameComponent.CORNER_RT:
        if (this.frameRectBuf_.height < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT || this.frameRectBuf_.width < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
          if (d === 'L') {
            this.frameRectBuf_.right -= amount
          } else if (d === 'T') {
            this.frameRectBuf_.top -= amount
          } else if (d === 'R') {
            this.frameRectBuf_.right += amount
          } else if (d === 'B') {
            this.frameRectBuf_.top += amount
          }
        } else {
          if (d === 'L') {
            this.frameRectBuf_.right -= amount
            if (this.frameRectBuf_.width < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
              this.frameRectBuf_.right += amount
            }
          } else if (d === 'T') {
            this.frameRectBuf_.top -= amount
          } else if (d === 'R') {
            this.frameRectBuf_.right += amount
          } else if (d === 'B') {
            this.frameRectBuf_.top += amount
            if (this.frameRectBuf_.height < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
              this.frameRectBuf_.top -= amount
            }
          }
        }
        break
      case FrameComponent.CORNER_LB:
        if (this.frameRectBuf_.height < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT || this.frameRectBuf_.width < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
          if (d === 'L') {
            this.frameRectBuf_.left -= amount
          } else if (d === 'T') {
            this.frameRectBuf_.bottom -= amount
          } else if (d === 'R') {
            this.frameRectBuf_.left += amount
          } else if (d === 'B') {
            this.frameRectBuf_.bottom += amount
          }
        } else {
          if (d === 'L') {
            this.frameRectBuf_.left -= amount
          } else if (d === 'T') {
            this.frameRectBuf_.bottom -= amount
            if (this.frameRectBuf_.height < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
              this.frameRectBuf_.bottom += amount
            }
          } else if (d === 'R') {
            this.frameRectBuf_.left += amount
            if (this.frameRectBuf_.width < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
              this.frameRectBuf_.left -= amount
            }
          } else if (d === 'B') {
            this.frameRectBuf_.bottom += amount
          }
        }
        break
      case FrameComponent.CORNER_RB:
        if (this.frameRectBuf_.height < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT || this.frameRectBuf_.width < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
          if (d === 'L') {
            this.frameRectBuf_.right -= amount
          } else if (d === 'T') {
            this.frameRectBuf_.bottom -= amount
          } else if (d === 'R') {
            this.frameRectBuf_.right += amount
          } else if (d === 'B') {
            this.frameRectBuf_.bottom += amount
          }
        } else {
          if (d === 'L') {
            this.frameRectBuf_.right -= amount
            if (this.frameRectBuf_.width < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
              this.frameRectBuf_.right += amount
            }
          } else if (d === 'T') {
            this.frameRectBuf_.bottom -= amount
            if (this.frameRectBuf_.height < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
              this.frameRectBuf_.bottom += amount
            }
          } else if (d === 'R') {
            this.frameRectBuf_.right += amount
          } else if (d === 'B') {
            this.frameRectBuf_.bottom += amount
          }
        }
        break

      case FrameComponent.ALL:
        if (d === 'L') {
          this.frameRectBuf_.left -= amount
          this.frameRectBuf_.right -= amount
        } else if (d === 'T') {
          this.frameRectBuf_.top -= amount
          this.frameRectBuf_.bottom -= amount
        } else if (d === 'R') {
          this.frameRectBuf_.left += amount
          this.frameRectBuf_.right += amount
        } else if (d === 'B') {
          this.frameRectBuf_.top += amount
          this.frameRectBuf_.bottom += amount
        }
        break
    }

    if (this.frameRectBuf_.left > this.frameRectBuf_.right) {
      if (fc === FrameComponent.CORNER_LT || fc === FrameComponent.CORNER_LB) {
        this.frameRectBuf_.left = this.frameRectBuf_.right
      } else {
        this.frameRectBuf_.right = this.frameRectBuf_.left
      }
    }

    if (this.frameRectBuf_.top > this.frameRectBuf_.bottom) {
      if (fc === FrameComponent.CORNER_LT || fc === FrameComponent.CORNER_RT) {
        this.frameRectBuf_.top = this.frameRectBuf_.bottom
      } else {
        this.frameRectBuf_.bottom = this.frameRectBuf_.top
      }
    }

    if (beforeLeft !== this.frameRectBuf_.left ||
      beforeTop !== this.frameRectBuf_.top ||
      beforeRight !== this.frameRectBuf_.right ||
      beforeBottom !== this.frameRectBuf_.bottom) {
      this.removeFrameLabel()
    }

    this.frameRect_.copy(this.frameRectBuf_)

    this.frame_.setFrameCoordinate(this.frameRect_)

    this.fireOnFrameChangeEvent()
  }

  private moveFrameCorner (pointer: Pointer, fc: FrameComponent): void {
    if (!this.editable_ || !this.frame_) {
      return
    }
    this.frameRectBuf_.copy(this.frameRect_)

    const diffX = pointer.differenceOfPosition.x
    const diffY = pointer.differenceOfPosition.y

    const beforeLeft = this.frameRectBuf_.left
    const beforeTop = this.frameRectBuf_.top
    const beforeRight = this.frameRectBuf_.right
    const beforeBottom = this.frameRectBuf_.bottom
    switch (fc) {
      case FrameComponent.CORNER_LT:
        if (this.frameRectBuf_.height < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT || this.frameRectBuf_.width < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
          this.frameRectBuf_.left += diffX
          this.frameRectBuf_.top += diffY
        } else {
          this.frameRectBuf_.left += diffX
          this.frameRectBuf_.top += diffY
          if (this.frameRectBuf_.width < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
            this.frameRectBuf_.left -= diffX
          }
          if (this.frameRectBuf_.height < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
            this.frameRectBuf_.top -= diffY
          }
        }
        break
      case FrameComponent.CORNER_RT:
        if (this.frameRectBuf_.height < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT || this.frameRectBuf_.width < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
          this.frameRectBuf_.right += diffX
          this.frameRectBuf_.top += diffY
        } else {
          this.frameRectBuf_.right += diffX
          this.frameRectBuf_.top += diffY
          if (this.frameRectBuf_.width < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
            this.frameRectBuf_.right -= diffX
          }
          if (this.frameRectBuf_.height < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
            this.frameRectBuf_.top -= diffY
          }
        }
        break
      case FrameComponent.CORNER_LB:
        if (this.frameRectBuf_.height < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT || this.frameRectBuf_.width < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
          this.frameRectBuf_.left += diffX
          this.frameRectBuf_.bottom += diffY
        } else {
          this.frameRectBuf_.left += diffX
          this.frameRectBuf_.bottom += diffY
          if (this.frameRectBuf_.width < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
            this.frameRectBuf_.left -= diffX
          }
          if (this.frameRectBuf_.height < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
            this.frameRectBuf_.bottom -= diffY
          }
        }
        break
      case FrameComponent.CORNER_RB:
        if (this.frameRectBuf_.height < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT || this.frameRectBuf_.width < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
          if (pointer.startPosition.x > pointer.prevPosition.x && pointer.startPosition.y < pointer.prevPosition.y) {
            this.currentFrameConponent_ = FrameComponent.CORNER_LB
            break
          }
          if (pointer.startPosition.x < pointer.prevPosition.x && pointer.startPosition.y > pointer.prevPosition.y) {
            this.currentFrameConponent_ = FrameComponent.CORNER_RT
            break
          }
          if (pointer.startPosition.x > pointer.prevPosition.x && pointer.startPosition.y > pointer.prevPosition.y) {
            this.currentFrameConponent_ = FrameComponent.CORNER_LT
            break
          }
          this.frameRectBuf_.right += diffX
          this.frameRectBuf_.bottom += diffY
        } else {
          this.frameRectBuf_.right += diffX
          this.frameRectBuf_.bottom += diffY
          if (this.frameRectBuf_.width < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
            this.frameRectBuf_.right -= diffX
          }
          if (this.frameRectBuf_.height < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
            this.frameRectBuf_.bottom -= diffY
          }
        }
        break
    }

    if (this.frameRectBuf_.left > this.frameRectBuf_.right) {
      if (fc === FrameComponent.CORNER_LT || fc === FrameComponent.CORNER_LB) {
        this.frameRectBuf_.left = this.frameRectBuf_.right
      } else {
        this.frameRectBuf_.right = this.frameRectBuf_.left
      }
    }

    if (this.frameRectBuf_.top > this.frameRectBuf_.bottom) {
      if (fc === FrameComponent.CORNER_LT || fc === FrameComponent.CORNER_RT) {
        this.frameRectBuf_.top = this.frameRectBuf_.bottom
      } else {
        this.frameRectBuf_.bottom = this.frameRectBuf_.top
      }
    }

    if (beforeLeft !== this.frameRectBuf_.left ||
      beforeTop !== this.frameRectBuf_.top ||
      beforeRight !== this.frameRectBuf_.right ||
      beforeBottom !== this.frameRectBuf_.bottom) {
      this.removeFrameLabel()
    }

    this.frameRect_.copy(this.frameRectBuf_)

    this.frame_.setFrameCoordinate(this.frameRect_)
  }

  private onClick (event: MouseEvent | TouchEvent, pointerManager: PointerManager): boolean {
    if (!this.enabled_) {
      return false
    }
    if (event instanceof MouseEvent) {
      const pt = pointerManager.getPointer(PointerManager.MOUSE_IDENTIFIER)
      if (this.currentFrameConponent_ === FrameComponent.OUTSIDE) {
        if (pt && this.frameIsDrawn_) {
          if (pt.startPosition.x === pt.prevPosition.x && pt.startPosition.y === pt.prevPosition.y) {
            this.resetFrameCoordinate()
            this.frameIsDrawn_ = false
          }
        }
      } else {
        if (this.frameRectBuf_.height < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT || this.frameRectBuf_.width < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
          this.resetFrameCoordinate()
          this.frameIsDrawn_ = false
        }
      }

      pointerManager.removePointerByID(PointerManager.MOUSE_IDENTIFIER)
    } else {
      const lpt = pointerManager.getLastPointer()
      if (this.currentFrameConponent_ === FrameComponent.OUTSIDE) {
        if (lpt && this.frameIsDrawn_) {
          this.resetFrameCoordinate()
          this.frameIsDrawn_ = false
        }
      } else {
        if (this.frameRectBuf_.height < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT || this.frameRectBuf_.width < PrintCroppingLayer.AREA_SIZE_MIN_LIMIT) {
          this.resetFrameCoordinate()
          this.frameIsDrawn_ = false
        }
      }
    }
    return true
  }

  private pointerUp (event: MouseEvent | TouchEvent, pointerManager: PointerManager): boolean {
    if (!this.enabled_) {
      return false
    }

    let res = false
    if (this.frameDragging_) {
      res = true
    }

    if (event instanceof MouseEvent) {
      pointerManager.setToCoverViewerCursor('grab')
    } else {
      for (let i = 0; i < event.touches.length; ++i) {
        const p = pointerManager.getPointer(event.touches[i].identifier)
        if (p) {
          pointerManager.removePointer(p)
        }
      }
    }
    this.frameDragging_ = false
    return res
  }

  private detectPointingComponentOfFrame (pointerManager: PointerManager, pointer?: Pointer): FrameComponent {
    pointerManager.setToCoverViewerCursor('')

    if (!pointer) {
      return FrameComponent.OUTSIDE
    }

    let res: FrameComponent = FrameComponent.OUTSIDE

    const posX = pointer.position.x
    const posY = pointer.position.y

    const detectMargin = this.edgeDetectSize_ * DeviceInfo.DPR

    // inside
    if ((this.frameRect_.left - detectMargin) <= posX && posX <= (this.frameRect_.right + detectMargin) &&
          (this.frameRect_.top - detectMargin) <= posY && posY <= (this.frameRect_.bottom + detectMargin)) {
      if (Math.abs(this.frameRect_.left - posX) <= detectMargin) {
        // エッジ検証 左

        // 左上コーナー
        if (Math.abs(this.frameRect_.top - posY) <= detectMargin) {
          res = FrameComponent.CORNER_LT

          if (this.editable_) {
            pointerManager.setToCoverViewerCursor('nw-resize')
          }
        } else if (Math.abs(this.frameRect_.bottom - posY) <= detectMargin) {
          // 左下コーナー
          res = FrameComponent.CORNER_LB

          if (this.editable_) {
            pointerManager.setToCoverViewerCursor('sw-resize')
          }
        } else {
          // 左エッジ
          res = FrameComponent.EDGE_LEFT

          if (this.editable_) {
            pointerManager.setToCoverViewerCursor('w-resize')
          }
        }
      } else if (Math.abs(this.frameRect_.top - posY) <= detectMargin) {
        // エッジ検証 上

        // 左上コーナー
        if (Math.abs(this.frameRect_.left - posX) <= detectMargin) {
          res = FrameComponent.CORNER_LT

          if (this.editable_) {
            pointerManager.setToCoverViewerCursor('nw-resize')
          }
        } else if (Math.abs(this.frameRect_.right - posX) <= detectMargin) {
          // 右上コーナー
          res = FrameComponent.CORNER_RT

          if (this.editable_) {
            pointerManager.setToCoverViewerCursor('ne-resize')
          }
        } else {
          // 上エッジ
          res = FrameComponent.EDGE_TOP

          if (this.editable_) {
            pointerManager.setToCoverViewerCursor('n-resize')
          }
        }
      } else if (Math.abs(this.frameRect_.right - posX) <= detectMargin) {
        // エッジ検証 右

        // 右上コーナー
        if (Math.abs(this.frameRect_.top - posY) <= detectMargin) {
          res = FrameComponent.CORNER_RT

          if (this.editable_) {
            pointerManager.setToCoverViewerCursor('ne-resize')
          }
        } else if (Math.abs(this.frameRect_.bottom - posY) <= detectMargin) {
          // 右下コーナー
          res = FrameComponent.CORNER_RB

          if (this.editable_) {
            pointerManager.setToCoverViewerCursor('se-resize')
          }
        } else {
          // 右エッジ
          res = FrameComponent.EDGE_RIGHT

          if (this.editable_) {
            pointerManager.setToCoverViewerCursor('e-resize')
          }
        }
      } else if (Math.abs(this.frameRect_.bottom - posY) <= detectMargin) {
        // エッジ検証 下

        // 左下コーナー
        if (Math.abs(this.frameRect_.left - posX) <= detectMargin) {
          res = FrameComponent.CORNER_LB

          if (this.editable_) {
            pointerManager.setToCoverViewerCursor('sw-resize')
          }
        } else if (Math.abs(this.frameRect_.right - posX) <= detectMargin) {
          // 右下コーナー
          res = FrameComponent.CORNER_RB

          if (this.editable_) {
            pointerManager.setToCoverViewerCursor('se-resize')
          }
        } else {
          // 下エッジ
          res = FrameComponent.EDGE_BOTTOM

          if (this.editable_) {
            pointerManager.setToCoverViewerCursor('s-resize')
          }
        }
      } else {
        res = FrameComponent.INSIDE
        pointerManager.setToCoverViewerCursor('move')
      }
    } else {
      res = FrameComponent.OUTSIDE
      pointerManager.setToCoverViewerCursor('grab')
    }

    return res
  }

  /**
   * フレームラベルの内容を変更する
   * @param text
   */
  public setFrameLabel (text: string): void {
    if (!this.frame_) {
      return
    }
    this.frame_.setLabelText(text)
  }

  /**
   * フレームラベルを削除する
   */
  public removeFrameLabel (): void {
    if (!this.frame_) {
      return
    }
    this.frame_.setLabelText()
  }

  public selectComponent (component?: string) {
    if (!this.frame_) {
      return
    }
    this.frame_.selectComponent(component)
  }

  /* 不要か？
  public diselectComponent(): void{
    if(!this.frame_){
      return
    }
    this.frame_.selectComponent()
  }
  */

  /**
   * フレームを初期状態に戻す
   */
  public resetFrameCoordinate (): void{
    const frameRoot = this.frame_?.getFrameRoot()
    if (frameRoot) {
      this.frameRect_ = new PhysicalRect()
      this.frameRectBuf_ = new PhysicalRect()
      frameRoot.style.left = '0'
      frameRoot.style.top = '0'
      frameRoot.style.width = '0'
      frameRoot.style.height = '0'
      frameRoot.style.display = 'none'
    }

    const frameEdgeRoot = this.frame_?.getFrameEdgeRoot()
    if (frameEdgeRoot) {
      frameEdgeRoot.style.left = '0'
      frameEdgeRoot.style.top = '0'
      // frameEdgeRoot.style.width = '0'
      // frameEdgeRoot.style.height = '0'
      frameEdgeRoot.style.display = 'none'
    }
  }
}

export { PrintCroppingLayer }
