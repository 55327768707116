
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'submit',
    },
  },
})
