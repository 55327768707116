// 検索結果一覧画面の表示切替用
import { SearchMode, initialSearchMode } from '@/data/@types/SearchMode'
import { displayList } from '@/data/selector/displayList'
import { displayModeList } from '@/data/selector/displayModeList'
import { sortList } from '@/data/selector/sortList'

/**
 * ステート
 */
const state = {
  isFirstTransition: true,
}

/**
 * ゲッター
 */
const getters = {
  isFirstTransition (state: any) {
    return state.isFirstTransition
  },
  searchMode () {
    const cookieSearchMode = <any>[]
    const isServer = typeof document === 'undefined'
    if (!isServer) {
      document.cookie.split(';').map(cookie => {
        if (cookie.split('=')[0].includes('searchMode')) {
          cookieSearchMode.push(JSON.parse(cookie.split('=')[1]))
        }
      })
      return cookieSearchMode[0] || initialSearchMode
    }
  },
}

/**
 * ミューテーション
 */
const mutations = {
  UPDATE_SEARCH_STATE (state: any, flag: boolean) {
    state.isFirstTransition = flag
  },
}

/**
 * アクション
 */
const actions = {
  selectDisplayMode (context: any, mode: string) {
    const searchMode: SearchMode = context.getters.searchMode
    if (mode && displayModeList.filter(
      checkDisplayMode => checkDisplayMode.value.toString() === mode.toString()
    ).length > 0) {
      searchMode.displayMode = mode
    }
    context.dispatch('setSearchMode', searchMode)
  },
  selectDisplayCount (context: any, displayCount: number): void {
    const searchMode: SearchMode = context.getters.searchMode
    if (displayCount && displayList.filter(
      checkDisplayCount => checkDisplayCount.value.toString() === displayCount.toString()
    ).length > 0) {
      searchMode.pageSize = displayCount.toString()
    }
    context.dispatch('setSearchMode', searchMode)
  },
  selectSortKey (context: any, sortKey: string): void {
    const searchMode: SearchMode = context.getters.searchMode
    if (sortKey && sortList.filter(
      checkSortKey => checkSortKey.value.toString() === sortKey.toString()
    ).length > 0) {
      searchMode.sortKey = sortKey
    }
    context.dispatch('setSearchMode', searchMode)
  },
  changeFullTextInterval (context: any, fullTextInterval: boolean): void {
    const searchMode: SearchMode = context.getters.searchMode
    searchMode.fullTextInterval = fullTextInterval
    context.dispatch('setSearchMode', searchMode)
  },
  inputFtInterval (context: any, ftInterval: number): void {
    const searchMode: SearchMode = context.getters.searchMode
    searchMode.ftInterval = ftInterval
    context.dispatch('setSearchMode', searchMode)
  },
  setSearchMode (context: any, searchMode: SearchMode) {
    const isServer = typeof document === 'undefined'
    if (!isServer) {
      const now = new Date()
      now.setFullYear(now.getFullYear() + 1)
      document.cookie = `searchMode=${JSON.stringify(searchMode)};expires=${now.toUTCString()};`
    }
  },

}

export default {
  state,
  getters,
  mutations,
  actions,
}
