import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withModifiers as _withModifiers, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59cdb174"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "facet-panel-header has-background-modest" }
const _hoisted_2 = ["id", "aria-labelledby", "aria-expanded", "hidden"]
const _hoisted_3 = ["data-cy"]
const _hoisted_4 = {
  key: 0,
  class: "level-left"
}
const _hoisted_5 = {
  class: "level is-mobile flex-grow",
  "flex-wrap": ""
}
const _hoisted_6 = ["id"]
const _hoisted_7 = {
  key: 0,
  class: "facet-panel-footer level-center is-mobile"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloserController = _resolveComponent("CloserController")!
  const _component_AppCheckbox = _resolveComponent("AppCheckbox")!
  const _component_FacetPanelTree = _resolveComponent("FacetPanelTree")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_ctx.facetItems.length > 0 && _ctx.showFacetArea)
    ? (_openBlock(), _createElementBlock("section", {
        key: 0,
        class: _normalizeClass(`facet-panel facet-panel-${_ctx.name}`)
      }, [
        _createElementVNode("header", _hoisted_1, [
          _createElementVNode("h2", null, [
            _createVNode(_component_CloserController, {
              modelValue: _ctx.state.ariaSelected,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.ariaSelected) = $event)),
              icons: ['accordion-open', 'arrow-right'],
              "aria-controls": _ctx.getFacetPanelId(_ctx.name),
              class: "facet-panel-title has-text-table-cell-title"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getFacetTitle(_ctx.name)), 1)
              ]),
              _: 1
            }, 8, ["modelValue", "aria-controls"])
          ])
        ]),
        _createElementVNode("ul", {
          class: "facet-panel-items has-background-contents",
          id: _ctx.getFacetPanelId(_ctx.name),
          "aria-labelledby": `tab${_ctx.facet + 1}`,
          "aria-expanded": _ctx.state.ariaSelected,
          hidden: !_ctx.state.ariaSelected
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facetItems, (facetItem, i) => {
            return _withDirectives((_openBlock(), _createElementBlock("li", {
              key: facetItem,
              class: _normalizeClass({ selected: facetItem.isSelected }),
              "data-cy": 'facet-item-' + facetItem.keyAsString
            }, [
              (facetItem.key && _ctx.isNotTreeFacetItem(_ctx.name))
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_CloserController, { disabled: true }),
                    _createVNode(_component_AppCheckbox, {
                      modelValue: _ctx.checkedFacet,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkedFacet) = $event)),
                      onChange: ($event: any) => (_ctx.search(facetItem)),
                      value: facetItem.key,
                      class: "facet-panel-label has-text-normal",
                      isChecked: facetItem.isSelected,
                      hiddenCheckBox: _ctx.isHiddenCheckBox(facetItem)
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.getFacetLabel(facetItem.key)), 1),
                          _createElementVNode("span", {
                            id: `facet-${_ctx.name}-${facetItem.key}-count`
                          }, _toDisplayString(facetItem.docCount ? _ctx.toLocale(facetItem.docCount, _ctx.lang) : ''), 9, _hoisted_6)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["modelValue", "onChange", "value", "isChecked", "hiddenCheckBox"])
                  ]))
                : (_openBlock(), _createBlock(_component_FacetPanelTree, {
                    key: 1,
                    tree: facetItem,
                    name: _ctx.name,
                    checkedFacet: _ctx.checkedFacet,
                    search: _ctx.search
                  }, null, 8, ["tree", "name", "checkedFacet", "search"]))
            ], 10, _hoisted_3)), [
              [_vShow, (i < (_ctx.unit * _ctx.state.unitNum)) || !_ctx.hasShowMoreButton]
            ])
          }), 128))
        ], 8, _hoisted_2),
        (_ctx.hasShowMoreButton && (_ctx.facetItems.length > _ctx.unit))
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_AppButton, {
                onClick: _ctx.changeDisplaySize,
                onKeydown: _withKeys(_withModifiers(_ctx.changeDisplaySize, ["prevent"]), ["space","enter"]),
                id: "showMoreOrLessButton",
                "aria-expanded": _ctx.isShowingAllFacet,
                "aria-controls": `panel-panel-items-${_ctx.name}`,
                class: "has-text-link flex-grow",
                styled: "is-none"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.isShowingAllFacet ? _ctx.$t('parts.lessDisplay') : _ctx.$t('parts.moreDisplay')), 1)
                ]),
                _: 1
              }, 8, ["onClick", "onKeydown", "aria-expanded", "aria-controls"])
            ]))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}