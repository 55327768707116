import { getData, cacheGet } from '@/helpers/util/webApiUtil'
import Dexie, { Table } from 'dexie'

const BASE_URL = process.env.VUE_APP_API_BASE_URL
const isKn = process.env.VUE_APP_IS_KN === 'TRUE'

export type AccessToken = {
  id?: number
  pid?: string
  cid: string
  token: string
  timestamp: number
}

//
// Declare Database
//
class TokenDatabaseV2 extends Dexie {
  public tokens!: Table<AccessToken, number>; // id is number in this case

  public constructor () {
    super('TokenDatabaseV2')
    this.version(1).stores({
      tokens: '++id,pid,cid,token,timestamp',
    })
  }
}

let db: TokenDatabaseV2
try {
  db = new TokenDatabaseV2()
} catch (e) {
  db = {} as TokenDatabaseV2
  console.error('TokenDatabaseV2 init error')
  console.error(e)
  throw e
}

const find = (cid: string) => db.tokens && db.tokens.where('cid').equals(cid).first()

const findExpired = (): Promise<AccessToken[]> => {
  const nowDate = new Date()
  return db.tokens && db.tokens.where('timestamp').belowOrEqual(nowDate.getTime()).toArray()
}

const findByPid = (pid: string): Promise<AccessToken[]> => {
  return db.tokens && db.tokens.where('pid').equals(pid).toArray()
}

const count = () => db.tokens && db.tokens.count()

const findAll = () => db.tokens && db.tokens.toArray()

const clear = async () => db.tokens && db.tokens.clear()

const bulkDelete = async (keys: any[]) => db.tokens && db.tokens.bulkDelete(keys)

const deleteOlder = async (pid: string) => {
  // 同一PIDのtoken情報は削除
  const tokensOfPid = await findByPid(pid)
  const bulkDeletePidTokens = tokensOfPid.map(token => token.id)
  await bulkDelete(bulkDeletePidTokens)
  // １時間以上前のtokenは削除
  const tokensExpired = await findExpired()
  const bulkDeleteTimestampTokens = tokensExpired.map(token => token.id)
  bulkDelete(bulkDeleteTimestampTokens)
}

const insertToken = (token: AccessToken) => {
  return db.tokens && db.transaction('rw', db.tokens, () => db.tokens.add({ ...token }))
}

const insertTokens = (tokens: AccessToken[]) => {
  return db.tokens && db.transaction('rw', db.tokens, () => db.tokens.bulkAdd(tokens))
}

const responseToPromises = (tokens: any, timestamp: any, pid: string) => {
  const transformedTokens = Object.keys(tokens).map(key => {
    const token = {
      pid: pid,
      cid: key,
      token: tokens[key],
      timestamp: timestamp,
    }
    return token
  })
  return insertTokens(transformedTokens)
}

let fetching = false

class AccessTokenService {
  public static getTokenString = (token: AccessToken): string => {
    return encodeURIComponent(JSON.stringify(token))
  }

  public static get = (cid: string) => find(cid);

  public static findAll = (): Promise<AccessToken[]> => findAll();

  public static findByPid = (pid: string): Promise<AccessToken[]> => findByPid(pid);

  public static isEmpty = async (): Promise<boolean> => {
    const c = await count()
    return c === 0
  };

  public static async setAccessTokenAsync (pid: string): Promise<void> {
    const apiUrl = isKn ? 'restriction/kn/issue/token' : 'restriction/issue/token'
    await getData(`${BASE_URL}/${apiUrl}/${pid}`)
      .then(async (response: any) => {
        await deleteOlder(pid)
        if (response.tokens) {
          const tokens = response.tokens
          await responseToPromises(tokens, response.timestamp, pid)
        }
      })
      .catch(async (error) => {
        await deleteOlder(pid)
        console.error(error.message)
      })
  }

  public static setAccessToken (pid: string) {
    const apiUrl = isKn ? 'restriction/kn/issue/token' : 'restriction/issue/token'
    if (!fetching) {
      fetching = true
      getData(`${BASE_URL}/${apiUrl}/${pid}`).then(async (response: any) => {
        await deleteOlder(pid)
        if (response.tokens) {
          const tokens = response.tokens
          await responseToPromises(tokens, response.timestamp, pid)
        }
        fetching = false
      })
    }
  }
}

export { AccessTokenService }

// const state = {
//   token: [],
// }

// const getters = {
//   tokenString: (_: any) => (token: AccessToken): string => {
//     const param = {
//       timestamp: token.timestamp,
//       [token.cid]: token.token
//     }
//     return encodeURIComponent(JSON.stringify(param))
//   },
//   token: (state: any) => (cid: string): AccessToken => {
//     return state.token.filter((target: any) => target.cid === cid)
//   },
//   tokens(state: any): AccessToken[] {
//     return state.token
//   },
// }

// const mutations = {
//   CLEAR(state: any) {
//     state.token = []
//   },
//   SET_ACCESS_TOKEN(state: any, token: AccessToken[]) {
//     state.token = token
//   },
// }

// const actions = {
//   async setAccessTokenAsync(context: any, pid: string): Promise<void> {
//     await postData(`${BASE_URL}/restriction/issue/token`, { pid }).then(async (response: any) => {
//       context.commit('CLEAR')
//       if (response.tokens) {
//         const tokens = response.tokens
//         const list = Object.keys(tokens).map(key => ({
//           cid: key,
//           token: tokens[key],
//           timestamp: response.timestamp
//         }))
//         context.commit('SET_ACCESS_TOKEN', list)
//       }
//     })
//   },
//   setAccessToken(_: any, pid: string) {
//     postData(`${BASE_URL}/restriction/issue/token`, { pid }).then((response: any) => {
//       console.log(response)
//     })
//   },
// }

// export default {
//   actions,
//   mutations,
//   getters
// }
