
import { defineComponent, computed, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'
import InputSpinnerScale from '@/components/molecules/InputSpinnerScale.vue'
import AppSelect from '@/components/atoms/AppSelect.vue'
import BookMarkButton from '../../molecules/BookMarkButton.vue'
import AppInput from '@/components/atoms/AppInput.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { bindingDirectionList } from '@/data/selector/imageToolbarList'
import { ImageViewer, ViewMode } from '@/helpers/imageviewer/ImageViewer'
import { getTwoInOnePageIndex } from '@/domain/item/itemViewer/imageViewerUtil'
import { getSelector } from '@/data/selector/getSelector'
import makeUpdatedPidString from '@/domain/item/itemViewer/makeUpdatedPidString'

const iv = ImageViewer.getInstance()

export default defineComponent({
  components: {
    ButtonIcon,
    BookMarkButton,
    InputSpinnerScale,
    AppSelect,
    AppInput,
    DssIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    showLoadingIcon: {
      type: Boolean,
      required: true,
    },
  },
  setup (props) {
    const store = useStore()
    const stateModule = store.getters
    const router = useRouter()
    const route = useRoute()
    const i18n = useI18n()
    const lang = i18n.locale
    const isKn = process.env.VUE_APP_IS_KN === 'TRUE'
    const isRekion = process.env.VUE_APP_IS_REKION === 'TRUE'
    const isIframePage = computed(() => store.getters.isIframe)

    const currentContentIndex = computed(() => stateModule.contentNumber)
    const contents = computed(() => stateModule.contents)
    const directionList = computed(() => getSelector('bindingDirectionList', bindingDirectionList, i18n.t))
    const twoInOneMode = computed(() => stateModule.currentContentsBundle.layout)
    const processingData = computed(() => store.getters.processingData)

    const state = reactive({
      viewMode: 0,
      directionContinuous: '1',
      zoomRate: 100,
    })

    // 2in1セレクトボックスの初期値を設定
    onMounted(() => {
      if (twoInOneMode.value === 'single') {
        state.viewMode = ViewMode.TOW_IN_ONE
      } else {
        state.viewMode = ViewMode.KOMA
      }
    })

    const currentBundleContents = computed(() => {
      return store.getters.contents
    })

    const currentBindingDirection = computed({
      get: () => stateModule.bindingDirection,
      set: (val) => {
        store.commit('updateBindingDirection', val)
        localStorage.setItem('direction', val)
      },
    })

    const isBindingDirectionLtr = computed(() => currentBindingDirection.value === 'ltr')

    const currentFrameIndex = computed({
      get: () => stateModule.contentNumber + 1,
      set: (val) => {
        // コマ番号が入力されたとき
        if (Number(val) <= 0 || Number(val) > currentBundleContents.value?.length) {
          iv.changePage(0) // 存在しないコマ番号が指定されたときは1コマ目を表示
          return
        }
        store.commit('updateCurrentContentIndex', {
          currentContentIndex: (Number(val) - 1),
        })
        const currentBundleIndex = stateModule.bundleNumber
        // アイテム内の遷移のためヒストリーに残さずに遷移する
        if (route.query.keyword) {
          router.replace('/' + makeUpdatedPidString(props.item.pid) + '/' + (currentBundleIndex + 1) + '/' + Number(val) + '?keyword=' + route.query.keyword)
        } else {
          router.replace('/' + makeUpdatedPidString(props.item.pid) + '/' + (currentBundleIndex + 1) + '/' + Number(val))
        }
      },
    })

    // 次のコマへ移動
    const nextFrameIndex = () => {
      const nextPage = stateModule.contentNumber + 1 // 0オリジン
      if (nextPage < 0 || nextPage > currentBundleContents.value?.length) {
        iv.changePage(0) // 存在しないコマ番号が指定されたときは1コマ目を表示
        return
      }
      // 表示モードによって画像ビューアが持つコマ番号とアイテムが持つコマ番号が異なるため
      if (state.viewMode === ViewMode.TOW_IN_ONE) {
        // 2in1表示の場合
        const twoInOneNextPage = getTwoInOnePageIndex(nextPage - 1, processingData.value.addCoverPageFlag) + 1
        iv.changePage(twoInOneNextPage)
      } else {
        iv.changePage(nextPage)
      }
    }

    // 前のコマへ移動
    const prevFrameIndex = () => {
      const prevPage = stateModule.contentNumber - 1 // 0オリジン
      if (prevPage < 0 || prevPage > currentBundleContents.value?.length) {
        iv.changePage(0) // 存在しないコマ番号が指定されたときは1コマ目を表示
        return
      }
      // 表示モードによって画像ビューアが持つコマ番号とアイテムが持つコマ番号が異なるため
      if (state.viewMode === ViewMode.TOW_IN_ONE) {
        // 2in1表示の場合
        const twoInonePrevPage = getTwoInOnePageIndex(prevPage - 1, processingData.value.addCoverPageFlag)
        iv.changePage(twoInonePrevPage)
      } else {
        iv.changePage(prevPage)
      }
    }

    const bookmarkSt = computed(() => {
      const currentBundleIndex = stateModule.bundleNumber
      const currentContentIndex = stateModule.contentNumber

      return {
        pid: props.item.pid.split('/').pop(),
        bid: props.item.contentsBundles[currentBundleIndex].id,
        cid: props.item.contentsBundles[currentBundleIndex].contents[currentContentIndex].id,
      }
    })

    const changeFrameIndex = (btn: string) => {
      if ((currentBindingDirection.value === 'ltr' && btn === 'left') || (currentBindingDirection.value === 'rtl' && btn === 'right')) {
        nextFrameIndex()
      } else {
        prevFrameIndex()
      }
    }

    const toggleFullScreen = () => {
      if (document.fullscreenElement) {
        document.exitFullscreen()
      } else {
        const itemViewerRef = document.getElementById('full-screen-div')
        if (itemViewerRef) {
          itemViewerRef.requestFullscreen()
        }
      }
    }

    const fullScreenIcon = computed(() => {
      if (stateModule.fullScreen) {
        return 'arrow-to-inside'
      } else {
        return 'arrow-to-outside'
      }
    })

    const toggleShowPin = () => {
      store.commit('toggleFulltextPin')
    }

    const pinIcon = computed(() => {
      const pins = store.getters.getItemViewFulltextSnippetPins
      if (pins.length === 0) return 'nbsp'
      if (store.getters.showFulltextPin) return 'viewer-pin-disabled'
      return 'viewer-pin'
    })

    const fullScreenAriaLabel = computed(() => {
      if (store.getters.fullScreen) {
        return i18n.t('informationHeader.fullScreenAriaLabel')
      } else {
        return i18n.t('label.fullScreen')
      }
    })

    const fulltextPinAriaLabel = computed(() => {
      const pins = store.getters.getItemViewFulltextSnippetPins
      if (pins.length === 0) return ''
      if (store.getters.showFulltextPin) return i18n.t('informationHeader.fulltextPinAriaLabelDisable')
      return i18n.t('informationHeader.fulltextPinAriaLabelEnable')
    })

    const fullscreenEnabled = document.fullscreenEnabled

    // 非活性のフィールドを追加する必要があるためコンポーネント内で定義
    const viewModeList = computed(() => {
      if (twoInOneMode.value === 'single') {
        return [
          { id: 'singleKoma', value: ViewMode.KOMA },
          { id: 'doubleKoma', value: ViewMode.TOW_IN_ONE },
          { id: 'stream', value: ViewMode.SCROLL_TOW_IN_ONE_H },
        ]
      } else {
        return [
          { id: 'singleKoma', value: ViewMode.KOMA },
          { id: 'stream', value: ViewMode.SCROLL_KOMA_H },
        ]
      }
    })
    const viewModeListLocal = computed(() => getSelector('viewModeList', viewModeList.value, i18n.t))

    return {
      isKn,
      isRekion,
      isIframePage,
      state,
      stateModule,
      toggleFullScreen,
      fullScreenIcon,
      toggleShowPin,
      pinIcon,
      fullscreenEnabled,
      fullScreenAriaLabel,
      fulltextPinAriaLabel,
      currentBundleContents,
      currentFrameIndex,
      currentContentIndex,
      contents,
      currentBindingDirection,
      changeFrameIndex,
      isBindingDirectionLtr,
      bookmarkSt,
      directionList,
      viewModeListLocal,
      lang,
      prevFrameIndex,
      nextFrameIndex,
    }
  },
})
