export interface SearchMode {
  fullTextInterval: boolean,
  ftInterval: number,
  displayMode: string,
  pageSize: string,
  sortKey: string
}

export const initialSearchMode: SearchMode = {
  fullTextInterval: false,
  ftInterval: 400,
  displayMode: 'list',
  pageSize: '20',
  sortKey: 'SCORE',
}
