
import { defineComponent, watchEffect, ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import CollectionSummary from '@/components/organisms/CollectionSummary.vue'
import CollectionDetailedSearch from '@/components/organisms/CollectionDetailedSearch.vue'
import CollectionDetailedSearchBooks from '@/components/organisms/CollectionDetailedSearchBooks.vue'
import CollectionDetailedSearchMagazine from '@/components/organisms/CollectionDetailedSearchMagazine.vue'
import CollectionDetailedSearchOldMaterials from '@/components/organisms/CollectionDetailedSearchOldMaterials.vue'
import CollectionDetailedSearchDoctoralDissertations from '@/components/organisms/CollectionDetailedSearchDoctoralDissertations.vue'
import CollectionDetailedSearchMaterialPolitical from '@/components/organisms/CollectionDetailedSearchMaterialPolitical.vue'
import CollectionDetailedSearchOccupationOfJapan from '@/components/organisms/CollectionDetailedSearchOccupationOfJapan.vue'
import CollectionDetailedSearchPrange from '@/components/organisms/CollectionDetailedSearchPrange.vue'
import CollectionDetailedSearchHistoricalRecording from '@/components/organisms/CollectionDetailedSearchHistoricalRecording.vue'
import CollectionDetailedSearchOfficialGazette from '@/components/organisms/CollectionDetailedSearchOfficialGazette.vue'
import CollectionDetailedSearchPackagedDigitalPublications from '@/components/organisms/CollectionDetailedSearchPackagedDigitalPublications.vue'
import CollectionDetailedSearchOnlinePublications from '@/components/organisms/CollectionDetailedSearchOnlinePublications.vue'
import CollectionDetailedSearchNewspapers from '@/components/organisms/CollectionDetailedSearchNewspapers.vue'
import CollectionDetailedSearchMaterialsOnJapaneseEmigration from '@/components/organisms/CollectionDetailedSearchMaterialsOnJapaneseEmigration.vue'
import CollectionDetailedSearchAudioVisualMaterials from '@/components/organisms/CollectionDetailedSearchAudioVisualMaterials.vue'
import CollectionDetailedSearchMaps from '@/components/organisms/CollectionDetailedSearchMaps.vue'
import { queryConvertToSortKeyAndOrder } from '@/data/SortList'
import { useRequestBuilder } from '@/domain/item/SearchRequest'
import convertToArray from '@/helpers/util/convertToArray'
import { convertSingleArrayToString, createCollectionIdArray, isEmpty } from '@/helpers/util/queryUtils'
import CloserController from '../molecules/CloserController.vue'
import compareObjectRecursive from '@/helpers/util/compareObjectRecursive'

export default defineComponent({
  namd: 'CollectionDetail',
  components: {
    CollectionSummary,
    default: CollectionDetailedSearch,
    books: CollectionDetailedSearchBooks,
    magazine: CollectionDetailedSearchMagazine,
    newspapers: CollectionDetailedSearchNewspapers,
    materialsOnJapaneseEmigration: CollectionDetailedSearchMaterialsOnJapaneseEmigration,
    oldMaterials: CollectionDetailedSearchOldMaterials,
    doctoralDissertations: CollectionDetailedSearchDoctoralDissertations,
    materialPolitical: CollectionDetailedSearchMaterialPolitical,
    occupationOfJapan: CollectionDetailedSearchOccupationOfJapan,
    prange: CollectionDetailedSearchPrange,
    historicalRecording: CollectionDetailedSearchHistoricalRecording,
    officialGazette: CollectionDetailedSearchOfficialGazette,
    onlinePublications: CollectionDetailedSearchOnlinePublications,
    packagedDigitalPublications: CollectionDetailedSearchPackagedDigitalPublications,
    audioVisualMaterials: CollectionDetailedSearchAudioVisualMaterials,
    maps: CollectionDetailedSearchMaps,
    CloserController,
  },
  props: {
    collectionId: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()
    const isRekion = computed(() => route.meta.isRekion)
    const collectionMaster = store.getters.CollectionMaster
    const component = () => {
      switch (props.collectionId) {
        case 'A00001':
          return 'books' // 図書
        case 'A00002':
          return 'magazine' // 雑誌
        case 'A00003':
          return 'oldMaterials' // 古典籍資料（貴重書等）
        case 'A00014':
          return 'doctoralDissertations' // 博士論文
        case 'A00017':
          return 'materialPolitical' // 憲政資料
        case 'A00016':
          return 'occupationOfJapan' // 日本占領関係資料
        case 'A00019':
          return 'prange' // プランゲ文庫
        case 'A00024':
          return 'historicalRecording' // 歴史的音源
        case 'A00015':
          return 'officialGazette' // 官報
        case 'A00162':
          return 'packagedDigitalPublications' // パッケージ系電子出版
        case 'B00000':
          return 'onlinePublications' // 電子書籍・電子雑誌
        case 'A00022':
          return 'newspapers' // 新聞
        case 'A00173':
          return 'materialsOnJapaneseEmigration' // 日系移民関係資料
        case 'A00121':
          return 'audioVisualMaterials' // 録音・映像関係資料
        case 'A00152':
          return 'maps' // 地図
        default:
          return 'default'
      }
    }

    const makeSubCollectionList = (): any[] => {
      return createCollectionIdArray(props.collectionId, collectionMaster, route.query)
    }
    const makeClassicMaterialTypeList = (): any[] => {
      return route.query.classicMaterialTypeList ? convertToArray(route.query.classicMaterialTypeList) : []
    }
    const makeClassicManuscriptionList = (): any[] => {
      return route.query.classicManuscriptionList ? convertToArray(route.query.classicManuscriptionList) : []
    }
    const makeSubjectKanpoList = (): any[] => {
      return route.query.subjectKanpoList ? convertToArray(route.query.subjectKanpoList) : []
    }
    const makeFederalRegisterTypeList = (): any[] => {
      return route.query.federalRegisterTypeList ? convertToArray(route.query.federalRegisterTypeList) : []
    }
    const makeBibliographicLevel = (): any[] => {
      return route.query.bibliographicLevel ? convertToArray(route.query.bibliographicLevel) : []
    }

    const makeNdc = (): any[] => {
      return route.query.ndc ? convertToArray(route.query.ndc) : []
    }

    const collectionDetailSearch = async (where: any) => {
      await store.dispatch('collectionDetailSearch', where)
    }
    const { makeDateRangeQuery } = useRequestBuilder()

    // 1つ前の検索条件保存
    const queryCache = computed(() => store.getters.cacheQuery)
    const collectionCache = computed(() => store.getters.cacheCollection)

    onMounted(() => {
      store.dispatch('selectDisplayCount', route.query.pageSize)
      store.dispatch('selectSortKey', route.query.sortKey)
      store.dispatch('selectDisplayMode', route.query.displayMode)
    })

    watchEffect(() => {
      if (route.name !== 'CollectionDetailedSearchResult' && route.name !== 'RekionSearchResult') {
        return
      }

      // 配列の中身が1つの場合、配列ではなくString型に変換する必要がある(queryCacheとの比較のため)
      if (route.query != null) {
        route.query = convertSingleArrayToString(route.query)
      }

      // 検索条件が同じ場合は検索が行われない
      if (!isEmpty(route.query) && compareObjectRecursive(queryCache.value, route.query) && props.collectionId === collectionCache.value) return
      store.commit('CACHE_QUERY', {
        ...route.query,
      })
      store.commit('CACHE_COLLECTION', props.collectionId)
      // 公開範囲：チェック情報保持
      const accessRestrictions = route.meta.isRekion ? store.getters.getRekionAccessRestrictionsCheckList : store.getters.getAccessRestrictionsCheckList
      const queryAccessRestrictions = route.query.accessRestrictions ? convertToArray(route.query.accessRestrictions) : accessRestrictions
      route.meta.isRekion ? store.commit('SET_ACCESS_RESTRICTIONS', queryAccessRestrictions) : store.commit('SET_ACCESS_RESTRICTIONS', queryAccessRestrictions)

      const publicationDateRangeObject = makeDateRangeQuery(route)
      const collectionDateRangeObject = makeDateRangeQuery(route, 'collectionFrom', 'collectionTo')

      /** 巻号を除く以外の検索APIリクエストボディ定義 */
      const where = {
        collection: [props.collectionId],
        accessRestrictions: store.getters.getAccessRestrictionsCheckList,
        keyword: route.query.keyword ? route.query.keyword : '',
        title: route.query.title ? route.query.title : '',
        creator: route.query.creator ? route.query.creator : '',
        publisher: route.query.publisher ? route.query.publisher : '',
        eraType: route.query.eraType ? route.query.eraType : null,
        ...publicationDateRangeObject, // from,toの年月日

        // TODO デフォルト以外の項目の対応
        collectionEraType: route.query.collectionEraType ? route.query.collectionEraType : null,
        collectionFromYear: collectionDateRangeObject.fromYear,
        collectionFromMonth: collectionDateRangeObject.fromMonth,
        collectionFromDay: collectionDateRangeObject.fromDay,
        collectionToYear: collectionDateRangeObject.toYear,
        collectionToMonth: collectionDateRangeObject.toMonth,
        collectionToDay: collectionDateRangeObject.toDay,
        publicationPlace: route.query.publicationPlace ? route.query.publicationPlace : '',
        tableOfContents: route.query.tableOfContents ? route.query.tableOfContents : '',
        ndc: makeNdc(),
        ndlc: route.query.ndlc ? route.query.ndlc : '',
        identifierItem: route.query.identifierItem ? route.query.identifierItem : 'PID',
        identifier: route.query.identifier ? route.query.identifier : '',
        callNumber: route.query.callNumber ? route.query.callNumber : '',
        subject: route.query.subject ? route.query.subject : '',
        subCollection: makeSubCollectionList(),
        kotenSubject: route.query.kotenSubject ? route.query.kotenSubject : '',
        classicMaterialTypeList: makeClassicMaterialTypeList(),
        classicManuscriptionList: makeClassicManuscriptionList(),
        provenance: route.query.provenance ? route.query.provenance : '',
        series: route.query.series ? route.query.series : '',
        musicType: route.query.musicType ? route.query.musicType : '',
        subjectKanpoList: makeSubjectKanpoList(),
        volumeSubtitle: route.query.volumeSubtitle ? route.query.volumeSubtitle : '',
        partTitleKanpo: route.query.partTitleKanpo ? route.query.partTitleKanpo : '',
        partTitleKanpoNumber: route.query.partTitleKanpoNumber ? route.query.partTitleKanpoNumber : '',
        federalRegisterTypeList: makeFederalRegisterTypeList(),
        volumeFederalRegisterItemType: route.query.volumeFederalRegisterItemType ? route.query.volumeFederalRegisterItemType : '',
        volumeFederalRegister: route.query.volumeFederalRegister ? route.query.volumeFederalRegister : '',
        publicationName: route.query.publicationName ? route.query.publicationName : '',
        publicationVolume: route.query.publicationVolume ? route.query.publicationVolume : '',
        description: route.query.description ? route.query.description : '',
        provider: route.query.provider ? route.query.provider : '',
        bibliographicLevel: makeBibliographicLevel(),
        pageNum: route.query.pageNum ? route.query.pageNum.toString() : '0',
        fullText: true,
        pid: route.query.pid ? route.query.pid : '',
        bibId: route.query.bibId ? route.query.bibId : '',
        releaseNumber: route.query.releaseNumber ? route.query.releaseNumber.toString() : '',
      }
      store.dispatch('selectDisplayCount', route.query.pageSize)
      store.dispatch('selectSortKey', route.query.sortKey)
      const searchMode = store.getters.searchMode
      where.pageSize = searchMode ? searchMode.pageSize : 20
      where.sortKey = searchMode ? queryConvertToSortKeyAndOrder(searchMode.sortKey).sortKey : 'SCORE'
      where.order = searchMode ? queryConvertToSortKeyAndOrder(searchMode.sortKey).order : 'DESC'
      if (route.query.collection_facet && route.query.collection_facet.length) where.collection_facet = convertToArray(route.query.collection_facet)
      if (route.query.permission_facet && route.query.permission_facet.length) where.permission_facet = convertToArray(route.query.permission_facet)
      if (route.query.ndc_facet && route.query.ndc_facet.length) where.ndc_facet = convertToArray(route.query.ndc_facet)
      if (route.query.rekion_facet && route.query.rekion_facet.length) where.rekion_facet = convertToArray(route.query.rekion_facet)
      if (route.query.basis_facet && route.query.basis_facet.length) where.basis_facet = convertToArray(route.query.basis_facet)
      if (route.query.levelCode_facet && route.query.levelCode_facet.length) where.levelCode_facet = convertToArray(route.query.levelCode_facet)
      if (route.query.official_facet && route.query.official_facet.length) where.official_facet = convertToArray(route.query.official_facet)
      if (route.query.article_facet && route.query.article_facet.length) where.article_facet = convertToArray(route.query.article_facet)
      if (route.query.institution_facet && route.query.institution_facet.length) where.institution_facet = convertToArray(route.query.institution_facet)
      if (route.query.subject_facet && route.query.subject_facet.length) where.subject_facet = convertToArray(route.query.subject_facet)
      if (route.query.language_facet && route.query.language_facet.length) where.language_facet = convertToArray(route.query.language_facet)
      if (route.query.classic_facet && route.query.classic_facet.length) where.classic_facet = convertToArray(route.query.classic_facet)
      if (route.query.ghq_facet && route.query.ghq_facet.length) where.ghq_facet = convertToArray(route.query.ghq_facet)
      if (route.query.itemToSearch_facet && route.query.itemToSearch_facet.length) where.itemToSearch_facet = convertToArray(route.query.itemToSearch_facet)
      if (route.query.publishEra_facet && route.query.publishEra_facet.length) where.publishEra_facet = convertToArray(route.query.publishEra_facet)
      if (route.query.publishYear_facet && route.query.publishYear_facet.length) where.publishYear_facet = convertToArray(route.query.publishYear_facet)
      if (route.query.fromYear_facet) where.fromYear_facet = route.query.fromYear_facet
      if (route.query.toYear_facet) where.toYear_facet = route.query.toYear_facet

      const rekionWhere = {
        // 検索フォーム内容
        accessRestrictions: store.getters.getRekionAccessRestrictionsCheckList,
        collection: [props.collectionId],
        eraType: route.query.eraType ? route.query.eraType : 'AD',
        ...publicationDateRangeObject, // from,toの年月日
        keyword: route.query.keyword ? route.query.keyword : '',
        title: route.query.title ? route.query.title : '',
        creator: route.query.creator ? route.query.creator : '',
        publisher: route.query.publisher ? route.query.publisher : '',
        musicType: route.query.musicType ? route.query.musicType : '',
        pid: route.query.pid ? route.query.pid : '',
        productNumber: route.query.productNumber ? route.query.productNumber : '',
        // ページ情報
        pageNum: route.query.pageNum ? route.query.pageNum.toString() : '0',
        // 全文取得
        fullText: false,
      }
      store.dispatch('selectDisplayCount', route.query.pageSize)
      store.dispatch('selectSortKey', route.query.sortKey)
      const rekionSearchMode = store.getters.searchMode
      rekionWhere.pageSize = rekionSearchMode ? rekionSearchMode.pageSize : 20
      rekionWhere.sortKey = rekionSearchMode ? queryConvertToSortKeyAndOrder(rekionSearchMode.sortKey).sortKey : 'SCORE'
      rekionWhere.order = rekionSearchMode ? queryConvertToSortKeyAndOrder(rekionSearchMode.sortKey).order : 'DESC'
      if (route.query.collection_facet && route.query.collection_facet.length) rekionWhere.collection_facet = convertToArray(route.query.collection_facet)
      if (route.query.permission_facet && route.query.permission_facet.length) rekionWhere.permission_facet = convertToArray(route.query.permission_facet)
      if (route.query.ndc_facet && route.query.ndc_facet.length) rekionWhere.ndc_facet = convertToArray(route.query.ndc_facet)
      if (route.query.rekion_facet && route.query.rekion_facet.length) rekionWhere.rekion_facet = convertToArray(route.query.rekion_facet)
      if (route.query.basis_facet && route.query.basis_facet.length) rekionWhere.basis_facet = convertToArray(route.query.basis_facet)
      if (route.query.levelCode_facet && route.query.levelCode_facet.length) rekionWhere.levelCode_facet = convertToArray(route.query.levelCode_facet)
      if (route.query.official_facet && route.query.official_facet.length) rekionWhere.official_facet = convertToArray(route.query.official_facet)
      if (route.query.article_facet && route.query.article_facet.length) rekionWhere.article_facet = convertToArray(route.query.article_facet)
      if (route.query.institution_facet && route.query.institution_facet.length) rekionWhere.institution_facet = convertToArray(route.query.institution_facet)
      if (route.query.subject_facet && route.query.subject_facet.length) rekionWhere.subject_facet = convertToArray(route.query.subject_facet)
      if (route.query.language_facet && route.query.language_facet.length) rekionWhere.language_facet = convertToArray(route.query.language_facet)
      if (route.query.itemToSearch_facet && route.query.itemToSearch_facet.length) rekionWhere.itemToSearch_facet = convertToArray(route.query.itemToSearch_facet)
      if (route.query.publishEra_facet && route.query.publishEra_facet.length) rekionWhere.publishEra_facet = convertToArray(route.query.publishEra_facet)
      if (route.query.publishYear_facet && route.query.publishYear_facet.length) rekionWhere.publishYear_facet = convertToArray(route.query.publishYear_facet)
      if (route.query.fromYear_facet) rekionWhere.fromYear_facet = route.query.fromYear_facet
      if (route.query.toYear_facet) rekionWhere.toYear_facet = route.query.toYear_facet
      if (route.query.pid) where.pid = route.query.pid
      if (route.query.bibId) where.bibId = route.query.bibId
      if (route.query.releaseNumber) where.releaseNumber = route.query.releaseNumber

      // TODO USE CACHEするとファセットパネルの再押下/絞り込み解除ボタンによる絞り込み解除ができなくなる
      // collectionDetailWhereの宣言の中で定義すると古典籍もしくは官報のリクエストボディテストがundefindで落ちるため分けている
      if (props.collectionId === 'A00015') {
        where.includeVolumeNum = true
        collectionDetailSearch(where)
      } else if (route.name === 'RekionSearchResult') {
        collectionDetailSearch(rekionWhere)
      } else {
        const collectionDetailWhere = {
          ...where,
          includeVolumeNum: route.query.includeVolumeNum ? (route.query.includeVolumeNum === 'true') : true, // デフォルトオン
        }
        collectionDetailSearch(collectionDetailWhere)
      }
    })
    return {
      component: component(),
      isRekion,
      collectionDetailSearch,
      showMobile: ref(false),
    }
  },
})
