
import { defineComponent, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getDataCredential } from '@/helpers/util/webApiUtil'

const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL

export default defineComponent({
  components: {
  },
  setup () {
    const element = document.getElementsByTagName('html')!
    element[0].classList.remove('is-dark')
    element[0].classList.add('is-light')
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const path = computed(() => route.path)

    const setLoginUser = async (loginUser: any) => {
      await store.dispatch('setLoginUser', loginUser)
    }
    const setBannerMessage = async (message: any) => {
      await store.dispatch('setBannerMessage', message)
    }

    const loginUser = computed(() => store.getters.loginUser)
    const getLoginPage = (businessRoleGroupId: string) => {
      return businessRoleGroupId === '0003' ? 'online'
        : businessRoleGroupId === '0004' ? 'doctor'
          : businessRoleGroupId === '0005' ? 'supportVisual'
            : 'online'
    }
    return {
      route,
      router,
      loginUser,
      path,

      async logout () {
        const loginPage = loginUser.value.outerProviderTypeCode ? loginUser.value.outerProviderTypeCode : getLoginPage(loginUser.value.businessRoleGroupId)
        sessionStorage.removeItem('vuex')
        const url = `${BASE_URL}/outer/logout`
        getDataCredential(url)
        await setLoginUser({})
        await router.push(`/dms/${loginPage}/login`)
        await setBannerMessage([{
          id: '001',
          type: 'success',
          text: 'logoutMessage',
        }])
      },
    }
  },
})
