
import { defineComponent, onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'
import KeywordSearch from '@/components/molecules/KeywordSearch.vue'
import AppInput from '@/components/atoms/AppInput.vue'
import AppCheckbox from '@/components/atoms/AppCheckbox.vue'
import TextWithLeadingIcon from '@/components/molecules/TextWithLeadingIcon.vue'
import AddonViewFullTextList from './AddonViewFullTextList.vue'
import IconLoading from '@/components/atoms/IconLoading.vue'
import { ImageViewer } from '@/helpers/imageviewer/ImageViewer'
import { ItemViewFulltextSnippet } from '@/data/@types/FulltextSearchResult'

const iv = ImageViewer.getInstance()

export default defineComponent({
  components: {
    ButtonIcon,
    KeywordSearch,
    AppInput,
    AppCheckbox,
    TextWithLeadingIcon,
    AddonViewFullTextList,
    IconLoading,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    if (!props.item.contentsBundles) return

    const keyword = ref(route.query.keyword)
    const fullTextInterval = ref<boolean>(store.getters.searchMode.fullTextInterval ?? false)
    const ftInterval = ref<number>(store.getters.searchMode.ftInterval ?? 400)

    const isProcessing = computed(() => store.getters.addonViewFulltextProcessingFlag)

    const snippetSearch = () => {
      store.dispatch('addonViewFulltextSearch', { value: keyword.value, router, route, fullTextInterval: fullTextInterval.value, ftInterval: ftInterval.value })
    }

    const snippetTotalHit = computed(() => store.getters.getItemViewSnippetSearchHit)
    const results = computed(() => store.getters.getItemViewFulltextSnippetList)

    const hasFullTextData = computed(() => {
      for (const checkBundle of props.item.contentsBundles) {
        if (checkBundle?.extra?.fulltextPath) {
          return true
        }
      }
      return false
    })

    const selectedSnippetId = computed(() => store.getters.getSelectedSnippetId)
    const selectSnippet = ({ id, snippetId, contents, searchWord, x }: ItemViewFulltextSnippet) => {
      const divide = contents?.extra?.layout?.divide || 1
      const page = (x ?? 1) >= divide ? 'right' : 'left'
      // アイテム内遷移はヒストリーに残さずに遷移する
      router.replace({
        params: {
          bundleIndex: route.params.bundleIndex ? route.params.bundleIndex : '1',
          contentIndex: id,
        },
        query: {
          page,
          keyword: route.query.keyword,
        },
      })
      store.commit('enableFulltextPin')
      store.commit('updateCurrentContentIndex', { currentContentIndex: id - 1 })
      if (searchWord) {
        store.commit('setSelectedSnippetId', snippetId)
        iv.changeCurrentSearchHitPin(snippetId)
      }
    }

    return {
      results,
      keyword,
      fullTextInterval,
      ftInterval,
      snippetSearch,
      isProcessing,
      snippetTotalHit,
      hasFullTextData,
      selectedSnippetId,
      selectSnippet,
    }
  },
})
