import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-534694c3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "facet-panel-tree" }
const _hoisted_2 = ["data-cy"]
const _hoisted_3 = { class: "level is-mobile flex-grow flex-wrap" }
const _hoisted_4 = { class: "facet-panel-tree-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloserController = _resolveComponent("CloserController")!
  const _component_AppCheckbox = _resolveComponent("AppCheckbox")!
  const _component_FacetPanelRecursiveList = _resolveComponent("FacetPanelRecursiveList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{ selected: _ctx.tree.isSelected }, "facet-panel-tree-header level-left is-mobile"]),
      "data-cy": `facet-item-tree-${_ctx.tree.key}`
    }, [
      _createVNode(_component_CloserController, {
        disabled: !_ctx.tree.children,
        modelValue: _ctx.show,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
        icons: ['accordion-close', 'arrow-right'],
        "aria-label": _ctx.getFacetLabel(_ctx.tree.key)
      }, null, 8, ["disabled", "modelValue", "aria-label"]),
      _createVNode(_component_AppCheckbox, {
        modelValue: _ctx.checkedFacetKeys,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkedFacetKeys) = $event)),
        onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.search(_ctx.tree))),
        value: _ctx.tree.key,
        class: "facet-panel-tree-header-label flex-grow has-text-normal"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(_ctx.getFacetLabel(_ctx.tree.key)), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.toLocale(_ctx.tree.docCount, _ctx.lang)), 1)
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "value"])
    ], 10, _hoisted_2),
    _withDirectives(_createElementVNode("ul", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tree.children, (item) => {
        return (_openBlock(), _createBlock(_component_FacetPanelRecursiveList, {
          key: item.key,
          item: item,
          name: _ctx.name,
          checkedFacet: _ctx.checkedFacetKeys,
          search: _ctx.search
        }, null, 8, ["item", "name", "checkedFacet", "search"]))
      }), 128))
    ], 512), [
      [_vShow, _ctx.show]
    ])
  ]))
}