import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e4bb0dfc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "the-global-nav-inner"
}
const _hoisted_2 = {
  key: 0,
  class: "focus-move-button-group is-hidden-mobile"
}
const _hoisted_3 = { class: "the-global-navi-main has-animation" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "the-global-navi-sub has-animation" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheKnNav = _resolveComponent("TheKnNav")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_KeywordSearch = _resolveComponent("KeywordSearch")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AppSelect = _resolveComponent("AppSelect")!

  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(["the-global-nav has-text-modest", {
      'has-background-rekion-main': _ctx.isRekion,
      'has-background-kn-main': _ctx.isKn,
      'has-background-contents': !_ctx.isRekion && !_ctx.isKn,
    }])
  }, [
    (_ctx.isKn)
      ? (_openBlock(), _createBlock(_component_TheKnNav, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.isTopPage || _ctx.isRekionTopPage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_AppButton, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.focusMove('focus-move-button-of-search-form'))),
                  onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.focusMove('focus-move-button-of-search-form')), ["prevent"]), ["space","enter"])),
                  "data-cy": "focus-move-button-of-search-form",
                  class: "focus-move-button",
                  styled: "is-accent",
                  tabindex: "0"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('label.shortcut.top.focusMoveButtonOfSearchForm')), 1)
                  ]),
                  _: 1
                }),
                (_ctx.isTopPage)
                  ? (_openBlock(), _createBlock(_component_AppButton, {
                      key: 0,
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focusMove('focus-move-button-of-image-search'))),
                      onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(($event: any) => (_ctx.focusMove('focus-move-button-of-image-search')), ["prevent"]), ["space","enter"])),
                      "data-cy": "focus-move-button-of-image-search",
                      class: "focus-move-button",
                      styled: "is-accent",
                      tabindex: "0"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('label.shortcut.top.focusMoveButtonOfImageSearch')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_AppButton, {
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.focusMove('focus-move-button-of-collection'))),
                  onKeydown: _cache[5] || (_cache[5] = _withKeys(_withModifiers(($event: any) => (_ctx.focusMove('focus-move-button-of-collection')), ["prevent"]), ["space","enter"])),
                  "data-cy": "focus-move-button-of-collection",
                  class: "focus-move-button",
                  styled: "is-accent",
                  tabindex: "0"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('label.shortcut.top.focusMoveButtonOfCollection')), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_KeywordSearch, {
            class: _normalizeClass([{ 'is-hidden-desktop': !_ctx.showKeywordSearch }, "has-animation"]),
            modelValue: _ctx.keyword,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.keyword) = $event)),
            onSearch: _ctx.search,
            placeholder: _ctx.$t('label.keyword'),
            styled: `is-accent${_ctx.isRekion ? ' is-rekion-text' : ''}`,
            id: "the-header-item-search",
            label: _ctx.$t('label.keyword')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AppButton, {
                styled: "is-none",
                type: "submit",
                label: _ctx.$t('parts.search')
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('parts.search')), 1)
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }, 8, ["class", "modelValue", "onSearch", "placeholder", "styled", "label"]),
          _createElementVNode("ul", _hoisted_3, [
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, {
                to: { name: 'Help' },
                class: _normalizeClass({ 'has-text-modest': !_ctx.isRekion })
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("header.help")), 1)
                ]),
                _: 1
              }, 8, ["class"])
            ]),
            (!_ctx.isRekion)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (!_ctx.isLoggedIn)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                        _createElementVNode("button", {
                          id: "the-global-navi-login-button",
                          class: "global-button",
                          type: "button",
                          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$store.commit('SET_SHOW_LOGIN_MODAL', true))),
                          onKeydown: _cache[8] || (_cache[8] = _withKeys(_withModifiers(($event: any) => (_ctx.$store.commit('SET_SHOW_LOGIN_MODAL', true)), ["prevent"]), ["space","enter"]))
                        }, _toDisplayString(_ctx.$t("header.login")), 33)
                      ]))
                    : (!_ctx.isBuiltin && !_ctx.isLibsend)
                      ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                          _createElementVNode("button", {
                            id: "the-global-navi-my-collection-button",
                            class: "global-button",
                            type: "button",
                            onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.doMyCollection && _ctx.doMyCollection(...args))),
                            onKeydown: _cache[10] || (_cache[10] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.doMyCollection && _ctx.doMyCollection(...args)), ["prevent"]), ["space","enter"]))
                          }, _toDisplayString(_ctx.$t("label.myCollection")), 33)
                        ]))
                      : (_ctx.canLogout)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                            _createElementVNode("button", {
                              id: "the-global-navi-logout-button",
                              class: "global-button",
                              type: "button",
                              onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.doLogout && _ctx.doLogout(...args))),
                              onKeydown: _cache[12] || (_cache[12] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.doLogout && _ctx.doLogout(...args)), ["prevent"]), ["space","enter"]))
                            }, _toDisplayString(_ctx.$t("label.logout")), 33)
                          ]))
                        : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.userStatus())
              ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["the-global-navi-location", { 'has-text-title': !_ctx.isRekion, 'rekion-title': _ctx.isRekion }])
                  }, _toDisplayString(_ctx.userStatus()), 3)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("ul", _hoisted_8, [
            (!_ctx.isRekion)
              ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
                  _createVNode(_component_AppSelect, {
                    onChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.$emit('closeAccordion'))),
                    modelValue: _ctx.state.displayMode,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.state.displayMode) = $event)),
                    options: [
              { name: _ctx.$t('header.modeType.lightMode'), value: 'light' },
              { name: _ctx.$t('header.modeType.darkMode'), value: 'dark' },
            ],
                    styled: "is-none"
                  }, null, 8, ["modelValue", "options"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("li", null, [
              _createElementVNode("button", {
                class: "global-button",
                type: "button",
                onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.changeLocale && _ctx.changeLocale(...args))),
                onKeydown: _cache[16] || (_cache[16] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.changeLocale && _ctx.changeLocale(...args)), ["prevent"]), ["space","enter"]))
              }, _toDisplayString(_ctx.$t("header.language")), 33)
            ]),
            (_ctx.showStatisticsDownloadLink)
              ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
                  _createVNode(_component_router_link, {
                    to: { path: '/statistics' },
                    id: "the-global-navi-statistics-download-link"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("header.statisticsDownload")), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
  ], 2))
}